export type EnvironmentConfig = {
  production: boolean;

  cognito: {
    identityPoolId: string;
    userPoolId: string;
    region: string;
    userPoolWebClientId: string;
    mandatorySignIn: boolean;
    authenticationFlowType: 'USER_PASSWORD_AUTH';
  };
  api: {
    url: string;
  };
  eventApi: {
    url: string;
  }
  fixtures: {
    enable: boolean;
    delay: number;
  };

  storage?: {
    bucket: string;
    region: string;
  };

  stripe: {
    publishableKey: string;
  }

  debug: boolean;
  debugLevel: DebugLevel;
};

export enum DebugLevel {
  LOW = 1,
  MED,
  HIGH,
}
