import { InstanceType } from './enums';

export const INSTANCE_PRICING_MAP_HOURLY: Record<InstanceType, number> = {
  [InstanceType.SMALL]: 0.115,
  [InstanceType.MEDIUM]: 0.232,
  [InstanceType.LARGE]: 0.464,
  [InstanceType.XLARGE]: 0.928,
};

type BonusCalculator = (amount: number) => number;

type TopUpBonuses = {
  readonly [thresholdAmount: number]: BonusCalculator;
};

export const topUpBonus =
  (topUpBonuses: TopUpBonuses) =>
  (topUpAmount: number): number =>
    Object.entries(topUpBonuses).reduce(
      ([previousThreshold, previousBonusCalculator], [currentThreshold, currentBonusCalculator]) =>
        Number(currentThreshold) <= topUpAmount && Number(currentThreshold) > Number(previousThreshold)
          ? [currentThreshold, currentBonusCalculator]
          : [previousThreshold, previousBonusCalculator],
      ['0', ((_: number) => 0) as BonusCalculator]
    )[1](topUpAmount);

// TODO: Review these when updating bonuses
// noinspection JSUnusedGlobalSymbols
export const TOP_UP_BONUSES: TopUpBonuses = {
  25: (_) => 1,
  50: (_) => 5,
  100: (_) => 15,
};

export const BETA_TOP_UP_BONUSES: TopUpBonuses = {
  10: (_) => 1,
  25: (_) => 5,
  50: (_) => 15,
  100: (_) => 50,
};

export const getTopUpBonus = (topUpAmount: number): number => topUpBonus(BETA_TOP_UP_BONUSES)(topUpAmount);
