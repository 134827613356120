import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, mergeAll, Observable } from 'rxjs';
import { AppApiService } from '../services/app-api.service';
import { AppEventApiService } from "../services/app-event-api.service";
import { Auth } from 'aws-amplify';

const _COGNITO_AUTH_HEADER = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next)).pipe(mergeAll());
  }

  async handle(request: HttpRequest<unknown>, next: HttpHandler): Promise<Observable<HttpEvent<unknown>>> {
    if (!request.url.includes(this.injector.get(AppApiService).apiUrl) && !request.url.includes(this.injector.get(AppEventApiService).apiUrl)) {
      return next.handle(request);
    }

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const newHeaders = request.headers.set(_COGNITO_AUTH_HEADER, token);
    const newRequest = request.clone({ headers: newHeaders });

    return next.handle(newRequest);
  }
}
