import { Pipe, PipeTransform } from '@angular/core';
import { UserGame } from '../models/user-game';
import { Observable } from 'rxjs';
import { UserGameService } from '../services/user-game.service';

@Pipe({
  name: 'userGameToUserGame$',
})
export class UserGameToUserGame$Pipe implements PipeTransform {
  private readonly userGame$Store: { [userGameId: string]: Observable<UserGame> };
  constructor(private userGameService: UserGameService) {
    this.userGame$Store = {};
  }

  transform(userGame: UserGame, ..._args: unknown[]): Observable<UserGame> {
    if (!this.userGame$Store[userGame.__id]) {
      this.userGame$Store[userGame.__id] = this.userGameService.getUserGame$(userGame.__id);
    }
    return this.userGame$Store[userGame.__id];
  }
}
