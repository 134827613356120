import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { UserGame } from '../../models/user-game';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

export interface GameDeleteDialogInput {
  userGame: UserGame;
}

const DELETION_CONFIRMATION_STRING = 'DELETE';

@Component({
  selector: 'app-game-delete-dialog',
  templateUrl: './game-delete-dialog.component.html',
  styleUrls: ['./game-delete-dialog.component.scss'],
})
export class GameDeleteDialogComponent implements OnInit, OnDestroy {
  DELETION_CONFIRMATION_STRING = DELETION_CONFIRMATION_STRING;
  confirmationInput = new FormControl<string>('', [confirmationInputValidator]);
  private ngUnsubscribe = new Subject<void>();

  get userGame(): UserGame {
    return this.data.userGame;
  }
  constructor(
    @Optional() public dialogRef: MatDialogRef<GameDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GameDeleteDialogInput
  ) {}

  ngOnInit(): void {
    this.confirmationInput.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      if (value && value.trim() != value) {
        this.confirmationInput.setValue(value.trim());
      }
    });
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

const confirmationInputValidator = (control: AbstractControl): ValidationErrors | null =>
  control.value !== DELETION_CONFIRMATION_STRING ? { notConfirmationString: control.value } : null;
