import { Game } from '../models/game';
import { GameShortName } from '../lib/enums';

const GAME_LOGOS_MAP: { [key in GameShortName]: string } = {
  [GameShortName.VALHEIM]: 'logo_valheim.png',
  [GameShortName.EMPYRION]: 'Empyrion_logo.jpeg',
  [GameShortName.TF2]: 'tf2-logo.png',
  [GameShortName['7D2D']]: '7DAYS_LOGO_Horizontal_white_cropped.png',
  [GameShortName.SATISFACTORY]: 'satisfactory_logo_small.png',
  [GameShortName.TERRARIA]: 'TerrariaLogo.png',
  [GameShortName.COREKEEPER]: 'CoreKeeper-logo.png',
  [GameShortName.ENSHROUDED]: 'Enshrouded_logo.png',
};

export const getGameLogo = (game: Game): string => GAME_LOGOS_MAP[game.shortName] ?? 'default_img.jpg';
