import { Component, Input } from '@angular/core';
import { UserGameStateTracker } from '../../../lib/user-game-state-tracker';
import { UserGame } from '../../../models/user-game';
import { PathParts } from '../../../routing/lib';

@Component({
  selector: 'app-shell-nav-list-item',
  templateUrl: './shell-nav-list-item.component.html',
  styleUrls: ['./shell-nav-list-item.component.scss'],
})
export class ShellNavListItemComponent extends UserGameStateTracker {
  @Input() userGame: UserGame;
  @Input() isLast = false;


  protected readonly RootPaths = PathParts;
  protected readonly PathParts = PathParts;
}
