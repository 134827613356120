// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { DebugLevel, EnvironmentConfig } from './envType';

const region = 'ca-central-1';

export const environment: EnvironmentConfig = {
  production: false,

  cognito: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'ca-central-1:2a065832-c86a-4744-93e0-539f055eb7cf',
    // (optional) - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: '',
    userPoolId: 'ca-central-1_IQsee3K7q',
    region: region,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '2gpgq8bmf9anbp88e57r5isv63',
    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },

  fixtures: {
    enable: false,
    delay: 2000,
  },
  storage: {
    bucket: 'appdata-vg-staging',
    region,
  },
  api: {
    url: 'https://2wlrpt2zx8.execute-api.ca-central-1.amazonaws.com/prod',
  },
  eventApi: {
    url: 'https://mlnzxvrm5d.execute-api.ca-central-1.amazonaws.com/prod'
  },
  stripe: {
    publishableKey:
      'pk_test_51KjVZSG45AszJxV7zXR5aY7HDw7S0enFo0PTkn0ogPXY8qLFxV1ZrDuXrSSilGwVAGB02EIcTcs9h0yxwhsGjjAf00DLCS8ovS',
  },
  debug: true,
  debugLevel: DebugLevel.LOW,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
