import { Component, Inject, OnInit } from '@angular/core';
import { UserGame } from '../../models/user-game';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ServerDetailsDialogInput {
  userGame$: Observable<UserGame>;
}

@Component({
  selector: 'app-server-details-dialog',
  templateUrl: './server-details-dialog.component.html',
  styleUrls: ['./server-details-dialog.component.scss'],
})
export class ServerDetailsDialogComponent implements OnInit {
  userGame$: Observable<UserGame>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ServerDetailsDialogInput) {}

  ngOnInit(): void {
    this.userGame$ = this.data.userGame$;
  }
}
