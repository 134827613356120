import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserGame } from '../../models/user-game';
import { inject } from '@angular/core';
import { UserGameService } from '../../services/user-game.service';
import { AppApiService } from '../../services/app-api.service';
import { PathParams, PathParts } from '../lib';

export const userGame$Resolver: ResolveFn<Observable<UserGame>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const userGameService = inject(UserGameService);
  const appApiService = inject(AppApiService);
  const router = inject(Router);

  const userGameId = route.paramMap.get(PathParams.userGameId);
  if (!userGameId) {
    router.navigate([PathParts.error]).then();
    const error = new Error(`userGame$Resolver ran on path without ${PathParams.userGameId}`);
    console.error(error);
    throw error;
  }

  if (!userGameService.userGameIsStored(userGameId)) {
    appApiService.readUserGame$(userGameId);
  }

  return of(userGameService.getUserGame$(userGameId));
};
