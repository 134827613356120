import { Component } from '@angular/core';

@Component({
  selector: 'app-bonus-table',
  templateUrl: './bonus-table.component.html',
  styleUrls: ['./bonus-table.component.scss']
})
export class BonusTableComponent {

}
