<app-user-game-delete-wrapper [userGame]="userGame" #deleteWrapperComponent>
  <mat-card>
    <mat-card-content class="mat-body-1">
      <ng-container *ngIf="instanceIsRunning">
        <mat-icon aria-hidden="true">warning</mat-icon>
        Your server is not in an offline state. Any changes made to the configuration will not take effect until the
        server restarts.
      </ng-container>
    </mat-card-content>
    <mat-card-actions>
      <app-user-game-controls
        [userGame]="userGame"
        [showDelete]="true"
        [showStateIndicator]="true"
        [disableDelete]="instanceIsRunning"
        (deleteClicked)="deleteWrapperComponent.deleteGameServer()"
      ></app-user-game-controls>
    </mat-card-actions>
  </mat-card>
  <mat-card [ngStyle]="{ margin: '1em' }">
    <mat-card-header>
      <div mat-card-avatar appGameLogo [gameOrUserGame]="userGame"></div>
      <mat-card-title>
        {{ userGame.name }}
      </mat-card-title>
      <mat-card-subtitle> Region: {{ humanReadableRegion }} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Basic" [disabled]="disabled">
          <mat-card>
            <form *ngIf="userGameForm" [formGroup]="userGameForm">
              <div class="mat-toolbar-row">
                <h2>Game Name</h2>
              </div>
              <mat-form-field appearance="outline">
                <input matInput [formControl]="userGameForm.controls.name" placeholder="Game Name" />
              </mat-form-field>

              <div class="mat-toolbar-row">
                <h2>Choose your Server Type</h2>
              </div>
              <mat-form-field appearance="outline">
                <mat-label>Server Type</mat-label>
                <mat-select [formControl]="userGameForm.controls.class">
                  <mat-option *ngFor="let userGameClass of classOptions" [value]="userGameClass">
                    {{ userGameClass }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-divider></mat-divider>
            </form>
            <mat-card-actions>
              <span>
                <button mat-raised-button color="primary" (click)="onSubmitGameConfig()" [disabled]="disabled">
                  Save
                </button>
                <button mat-raised-button color="warn" (click)="onCancelGameConfig()" [disabled]="disabled">
                  Close
                </button>
              </span>
            </mat-card-actions>
          </mat-card>
        </mat-tab>
        <mat-tab label="Advanced" [disabled]="disabled">
          <app-game-config-item-form
            *ngIf="gameConfig; else spinner"
            (closeDialog)="onCancelGameConfig()"
            [userGameId]="userGame.__id"
            [configItems]="gameConfig.configItems"
            (formDisabled)="disableForm($event)"
          ></app-game-config-item-form>
          <ng-template #spinner>
            <div class="spinner-container">
              <mat-spinner></mat-spinner>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</app-user-game-delete-wrapper>
