import { Identifiable, Ref_Owner } from './_interfaceMixins';

export enum PaymentProvider {
  STRIPE_CHARGE = 'STRIPE_CHARGE',
}

export interface Payment extends Identifiable, Ref_Owner {
  provider: PaymentProvider;
  externalId?: string;
  clientToken?: string;
  amount: number;
}
