<mat-card>
  <mat-card-header>
    <mat-card-title [ngStyle]="{ marginBottom: '0.3em' }">Top Up My Credit</mat-card-title>
    <mat-card-subtitle>
      <app-throttled-button (release)="refreshBalance()">
        <div [ngStyle]="{ display: 'flex', alignItems: 'center' }">
          <mat-icon aria-hidden="true" [ngStyle]="{ marginRight: '0.3em' }">refresh</mat-icon>
          <span>Refresh Balance</span>
        </div>
      </app-throttled-button>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-vertical-stepper [linear]="true" [animationDuration]="'200'">
      <mat-step [stepControl]="amountForm">
        <ng-template matStepLabel>Choose a top up amount...</ng-template>
        <div [ngStyle]="{ display: 'flex', flexFlow: 'wrap' }">
          <div [ngStyle]="{ marginRight: '1em' }">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Amount</mat-label>
              <input matInput type="number" class="input-right-align" [formControl]="amountForm.controls.amount" />
              <span matTextPrefix>$&nbsp;</span>
              <span matTextSuffix>.00</span>
              <mat-hint> Minimum amount: {{ MINIMUM_ACCEPTABLE_PAYMENT | currency }}</mat-hint>
            </mat-form-field>

            <p>
              <span class="mat-body-strong">Free Bonus: </span> {{ getTopUpBonus(currentAmount) | currency }}
            </p>
            <p>
              <span class="mat-body-strong">Total Top Up: </span>
              {{ currentAmount + getTopUpBonus(currentAmount) | currency }}
            </p>

            <mat-checkbox [formControl]="amountForm.controls.confirmation">
              <mat-label
                [ngStyle]="{
                  fontFamily: 'var(--mat-form-field-subscript-text-font)',
                  fontWeight: 'var(--mat-form-field-subscript-text-weight)',
                  fontSize: 'var(--mat-form-field-subscript-text-size)',
                  color: 'var(--text-primary-color)'
                }"
              >
                I confirm the details above
              </mat-label>
            </mat-checkbox>
          </div>
          <mat-card>
            <mat-card-content>
              <app-bonus-table></app-bonus-table>
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="paymentForm">
        <ng-template matStepLabel>Payment Information</ng-template>

        <p>Please enter your payment details:</p>
        <small class="accent-color">
          (You will be asked to confirm everything on the next step before you are charged)
        </small>

        <form id="payment-form" class="stripe-container">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
        </form>
        <small class="primary-color"> PLEASE NOTE: We are not currently accepting payments from the EU </small>

        <app-powered-by-stripe-span></app-powered-by-stripe-span>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step [stepControl]="confirmPaymentForm" #confirmPaymentStep>
        <ng-template matStepLabel>Confirmation</ng-template>

        <mat-card>
          <ng-container *ngIf="stripeToken; else stripeLoading">
            <ng-container *ngIf="stripeToken.token; else stripeError"></ng-container>
            <ng-template #stripeError>
              <p class="primary-color">
                <ng-container *ngIf="stripeToken.error && stripeToken.error.type === 'card_error'; else nonCardError">
                  We couldn't process your Credit Card: {{ stripeToken.error.message }}
                </ng-container>
                <ng-template #nonCardError> Something went wrong, please re-enter your payment details... </ng-template>
              </p>
            </ng-template>
            <mat-card-header>
              <mat-card-title>Order Details</mat-card-title>
              <mat-card-subtitle>Please confirm this information</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <h3>Payment Amount: {{ amountForm.value.amount | currency }} (USD)</h3>

              <div [ngStyle]="{ maxWidth: '400px' }">
                <p *ngIf="stripeToken.token?.card?.last4 as last4" class="order-summary-line">
                  Credit card:
                  <span [ngStyle]="{ flex: '1 1 auto' }"></span>
                  <span class="text-align-end"> **** **** **** {{ last4 }} </span>
                </p>

                <p class="order-summary-line">
                  Bonus (not charged):
                  <span [ngStyle]="{ flex: '1 1 auto' }"></span>
                  <span class="text-align-end">
                    {{ getTopUpBonus(currentAmount) | currency }}
                  </span>
                </p>
              </div>
            </mat-card-content>

            <mat-card-actions [ngStyle]="{ flexFlow: 'wrap' }">
              <mat-checkbox [formControl]="confirmPaymentForm.controls.confirmation">
                <mat-label
                  [ngStyle]="{
                    fontFamily: 'var(--mat-form-field-subscript-text-font)',
                    fontWeight: 'var(--mat-form-field-subscript-text-weight)',
                    fontSize: 'var(--mat-form-field-subscript-text-size)',
                    color: 'var(--text-primary-color)'
                  }"
                >
                  I confirm the details above
                </mat-label>
              </mat-checkbox>

              <span [ngStyle]="{ flex: '1 1 auto' }"></span>

              <button
                mat-raised-button
                color="primary"
                [disabled]="!confirmPaymentForm.valid || confirmPaymentForm.disabled"
                (click)="onSubmitPayment()"
              >
                Pay {{ amountForm.value.amount | currency }}
              </button>
            </mat-card-actions>
          </ng-container>
          <ng-template #stripeLoading>
            <span [ngStyle]="{ display: 'flex' }">
              <span [ngStyle]="{ flex: '1 1 auto' }"></span>
              <span [ngStyle]="{ display: 'flex', flexDirection: 'column', alignItems: 'center' }">
                <span>Verifying your card details...</span>
                <mat-progress-spinner [diameter]="40" [mode]="'indeterminate'"></mat-progress-spinner>
              </span>
              <span [ngStyle]="{ flex: '1 1 auto' }"></span>
            </span>
          </ng-template>
        </mat-card>

        <div>
          <button mat-button matStepperPrevious>Back</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-flat-button color="accent" (click)="onCancel()">Cancel</button>
  </mat-card-actions>
</mat-card>
