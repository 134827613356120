<ng-container *ngIf="userGame | userGameToUserGame$ | async as currentUserGame">
  <mat-divider></mat-divider>
  <a
    mat-list-item
    [routerLink]="[RootPaths.mygames, PathParts.game, userGame.__id]"
    routerLinkActive
    #routerLinkActiveInstance="routerLinkActive"
    [activated]="routerLinkActiveInstance.isActive"
  >
    {{ userGame.name }}
  </a>
  <span [ngStyle]="{ display: 'flex', alignItems: 'baseline' }">
    <app-user-game-controls
      [userGame]="currentUserGame"
      [showDelete]="false"
      [showStateIndicator]="true"
    ></app-user-game-controls>
  </span>
  <mat-divider *ngIf="isLast"></mat-divider>
</ng-container>
