import { Injectable } from '@angular/core';
import { AmplifyUser, AuthenticatorServiceFacade } from '@aws-amplify/ui';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Store } from '@kto/rxjs-observable-store';
import { distinctUntilChanged, filter, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppApiService } from './app-api.service';
import { Router } from '@angular/router';
import { PathParts } from '../routing/lib';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsAcceptanceDialogComponent } from '../dialogs/terms-acceptance-dialog/terms-acceptance-dialog.component';
import { StoredStatus } from '@kto/modelled-api-store-service';

type AuthState = 'pending' | 'unauthenticated' | 'authenticated';
export interface UserState {
  user?: AmplifyUser;
  authState: AuthState;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends Store<UserState> {
  public ownerId$: Observable<string | undefined> = this.state$.pipe(
    map(({ user }) => {
      const userAttributes = user?.attributes;
      return userAttributes ? userAttributes['sub'] : undefined;
    }),
    distinctUntilChanged()
  );

  private termsAcceptanceDialogRef: MatDialogRef<TermsAcceptanceDialogComponent, boolean> | undefined;

  constructor(
    private authenticatorService: AuthenticatorService,
    private appApiService: AppApiService,
    private router: Router,
    public dialog: MatDialog
  ) {
    super({ authState: 'pending' });
    this.authenticatorService.subscribe(async (authenticatorState: AuthenticatorServiceFacade) => {
      if (authenticatorState.isPending) {
        this.patchState('pending', 'authState');
      }
      switch (authenticatorState.authStatus) {
        case 'authenticated': {
          this.setState({ ...this.state, authState: 'authenticated', user: authenticatorState.user });
          break;
        }
        case 'configuring': {
          this.setState({ authState: 'pending' });
          break;
        }
        case 'unauthenticated': {
          this.setState({ ...this.state, authState: 'unauthenticated', user: undefined });
          break;
        }
        default:
          // Should not happen
          console.warn(authenticatorState, 'Unexpected authenticator state');
      }
    });
  }

  get userState$(): Observable<UserState> {
    return this.state$;
  }

  get authState$(): Observable<AuthState> {
    return this.state$.pipe(
      map((userState) => userState.authState),
      distinctUntilChanged()
    );
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.authState$.pipe(map((authState) => authState === 'authenticated'));
  }

  async signOut(): Promise<void> {
    this.authenticatorService.signOut();
    this.appApiService.clearUserItemsFromStore();
    await this.router.navigate([PathParts.login]);
  }

  openTermsAcceptanceDialog(): void {
    if (!this.termsAcceptanceDialogRef) {
      this.termsAcceptanceDialogRef = this.dialog.open(TermsAcceptanceDialogComponent, {
        disableClose: true,
        panelClass: 'themed-overlay-color',
        height: '100vh',
        width: '100vw',
        maxHeight: '100vh',
        maxWidth: '100vw',
      });

      this.termsAcceptanceDialogRef
        .afterClosed()
        .pipe(
          map((termsOfServiceAccepted) => !!termsOfServiceAccepted),
          switchMap((termsOfServiceAccepted) =>
            this.ownerId$.pipe(
              filter((ownerId): ownerId is string => !!ownerId),
              map((ownerId) => ({ ownerId, termsOfServiceAccepted }))
            )
          )
        )
        .subscribe({
          next: async ({ ownerId, termsOfServiceAccepted }) => {
            this.appApiService
              .updateUserProfile$({
                ownerId,
                userProfile: { termsOfServiceAccepted },
              })
              .subscribe(async (storedUserProfile) => {
                if (storedUserProfile.state.status !== StoredStatus.PENDING) {
                  if (!termsOfServiceAccepted) {
                    await this.signOut();
                    setTimeout(() => window.location.assign('/'), 500);
                  } else {
                    window.location.assign('/');
                  }
                }
              });
          },
          complete: () => (this.termsAcceptanceDialogRef = undefined),
        });
    }
  }
}
