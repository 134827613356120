import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { UiOp, UiStateService } from '../../services/ui-state.service';
import { getCurrentCognitoSub } from '../../util/auth';

export const userIdResolver: ResolveFn<string> = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const uiStateService = inject(UiStateService);
  uiStateService.setGlobalSpinnerActive(UiOp.USER_SUB_LOAD, true);
  const ownerId = await getCurrentCognitoSub();
  uiStateService.setGlobalSpinnerActive(UiOp.USER_SUB_LOAD, false);
  return ownerId;
};
