<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Server Details</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class='card-content dashboard-card-overflow dialog-content'>
    <table *ngIf="userGame$ | async as userGame">
      <tr>
        <td>IP Address:</td>
        <td>{{ userGame.publicIpAddress }}</td>
      </tr>
      <tr>
        <td>FTP username:</td>
        <td>{{ userGame.ftpUsername }}</td>
      </tr>
      <tr>
        <td>FTP password:</td>
        <td>{{ userGame.ftpPassword }}</td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button mat-dialog-close>OK</button>
  </mat-card-actions>
</mat-card>
