import { Directive, HostBinding, Input, OnChanges, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { Game } from '../models/game';
import { UserGame } from '../models/user-game';
import { map, Subject, switchMap, takeUntil } from 'rxjs';
import { AppApiService } from '../services/app-api.service';
import { modelledItemOnly } from '../util/rxjs-pipes';
import { UserGameService } from '../services/user-game.service';
import { getGameLogo } from '../util/game';

interface GameLogoComponentChanges extends SimpleChanges {
  gameOrUserGame: SimpleChange;
  height: SimpleChange;
  width: SimpleChange;
}

@Directive({
  selector: '[appGameLogo]',
})
export class GameLogoDirective implements OnChanges, OnDestroy {
  @Input() gameOrUserGame: Game | UserGame;
  @HostBinding('style.height') @Input() height = '60px';
  @HostBinding('style.width') @Input() width = '150px';
  @HostBinding('style.border-radius') borderRadius = '5%';
  @HostBinding('style.background') background = 'var(--gruvbox-black-dark)';
  @HostBinding('style.border') border = 'thin solid var(--text-primary-color)';
  @HostBinding('style.background-size') backgroundSize = 'cover';
  @HostBinding('style.background-image') backgroundImage: string;
  private ngUnsubscribe = new Subject<void>();

  constructor(private userGameService: UserGameService, private appApiService: AppApiService) {}

  ngOnChanges(changes: GameLogoComponentChanges): void {
    if (gameChanged(changes)) {
      this.ngUnsubscribe.next();
      if (isGame(this.gameOrUserGame)) {
        this.backgroundImage = getGameUrl(this.gameOrUserGame);
      } else {
        this.userGameService
          .getUserGame$(this.gameOrUserGame.__id)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            switchMap(({ gameId }) => this.appApiService.game$(gameId)),
            modelledItemOnly(),
            map((game) => getGameUrl(game))
          )
          .subscribe((gameLogoUrl) => (this.backgroundImage = gameLogoUrl));
      }
    }
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

const gameChanged = (changes: GameLogoComponentChanges): boolean => {
  if (changes.gameOrUserGame.firstChange) {
    return true;
  }
  const { __id: previousId } = changes.gameOrUserGame.previousValue as Game | UserGame;
  const { __id: currentId } = changes.gameOrUserGame.currentValue as Game | UserGame;

  return previousId !== currentId;
};

const isGame = (gameOrUserGame: Game | UserGame): gameOrUserGame is Game => !!(gameOrUserGame as Game).shortName;

const getGameUrl = (game: Game): string => `url(assets/img/${getGameLogo(game)})`;
