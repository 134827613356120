import { Injectable } from '@angular/core';
import { Store } from '@kto/rxjs-observable-store';
import { distinctUntilChanged, map, Observable } from 'rxjs';

interface SpinnerActive {
  [uiOp: string]: boolean;
}

interface UiStoreState {
  globalSpinnerActive: SpinnerActive;
  tosConfirmationRequired: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UiStateService extends Store<UiStoreState> {
  constructor() {
    super({
      globalSpinnerActive: {},
      tosConfirmationRequired: false,
    });
  }

  get tosConfirmationRequired$(): Observable<boolean> {
    return this.state$.pipe(
      map((state) => state.tosConfirmationRequired),
      distinctUntilChanged()
    );
  }

  setTosConfirmationRequired(value: boolean): void {
    this.patchState(value, 'tosConfirmationRequired');
  }

  get globalSpinnerActive$(): Observable<boolean> {
    return this.state$.pipe(
      map((state) => Object.values(state.globalSpinnerActive).some((spinnerActiveState) => spinnerActiveState)),
      distinctUntilChanged()
    );
  }

  setGlobalSpinnerActive(uiOp: UiOp, value: boolean) {
    this.patchState(value, 'globalSpinnerActive', uiOp);
  }
}

export enum UiOp {
  REGION_CONFIGS_LOAD = 'REGION_CONFIGS_LOAD',
  GAMES_LOAD = 'GAMES_LOAD',
  USER_GAMES_LOAD = 'USER_GAMES_LOAD',
  USER_GAME_LOAD = 'USER_GAME_LOAD',
  USER_SUB_LOAD = 'USER_SUB_LOAD',
  GAME_CONFIGS = 'GAME_CONFIGS',
  LOGIN = 'LOGIN',
}
