import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserGame, UserGameState } from '../../models/user-game';
import { UserGameService } from '../../services/user-game.service';
import { Observable } from 'rxjs';
import { OwnerBalanceService } from '../../services/owner-balance.service';
import { UserGameStateTracker } from '../../lib/user-game-state-tracker';

@Component({
  selector: 'app-user-game-controls',
  templateUrl: './user-game-controls.component.html',
  styleUrls: ['./user-game-controls.component.scss'],
})
export class UserGameControlsComponent extends UserGameStateTracker {
  @Input() userGame: UserGame;
  @Input() showDelete = false;
  @Input() disableDelete = true;
  @Input() showStateIndicator = false;
  @Output() deleteClicked = new EventEmitter<void>();
  ownerBalanceAmount$: Observable<number> = this.ownerBalanceService.ownerBalanceAmount$;

  get serverCanStart(): boolean {
    return this.userGameState === UserGameState.INSTANCE_OFFLINE;
  }

  get serverCanStop(): boolean {
    return (
      this.userGameState >= UserGameState.USER_REQUESTED_START &&
      this.userGameState < UserGameState.USER_REQUESTED_TERMINATION
    );
  }

  get serverCanRestart(): boolean {
    return this.serverCanStop;
  }


  get userGameStateClass(): { [klass: string]: any } {
    return {
      'user-game-starting': this.serverIsStarting,
      'user-game-running': this.serverIsRunning,
      'user-game-restarting': this.serverIsRestarting,
      'user-game-stopping': this.serverIsStopping,
      'user-game-offline': this.instanceIsOffline,
    };
  }

  constructor(private userGameService: UserGameService, private ownerBalanceService: OwnerBalanceService) {
    super();
  }

  async restartGameServer(): Promise<void> {
    if (this.serverCanRestart) {
      try {
        await this.userGameService.restartUserGame(this.userGame.__id);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async startGameServer(): Promise<void> {
    if (this.serverCanStart) {
      try {
        await this.userGameService.startUserGame(this.userGame.__id);
      } catch (e) {
        // TODO: Error handling
        console.error(e);
      }
    }
  }
  async stopGameServer(): Promise<void> {
    if (this.serverCanStop) {
      try {
        await this.userGameService.stopUserGame(this.userGame.__id);
      } catch (e) {
        // TODO: Error handling
        console.error(e);
      }
    }
  }
}
