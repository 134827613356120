import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export const EVENT_API_URL = 'EVENT_API_URL';

export enum EventTypes {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  START = 'START',
  STOP = 'STOP',
  RESTART_GAME_SERVER = 'RESTART_GAME_SERVER',
  GET_STATUS = 'GET_STATUS',
}

@Injectable({
  providedIn: 'root',
})
export class AppEventApiService {
  constructor(private http: HttpClient, @Inject(EVENT_API_URL) public readonly apiUrl: string) {}

  sendEvent$(eventType: EventTypes, eventData: any): Observable<any> {
    const requestBody = {
      gameServerEventType: eventType,
      payload: eventData,
    };

    return this.http.post(this.apiUrl, requestBody);
  }
}
