import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/auth/login/login.component';
import { PathParams, PathParts } from './lib';
import { UserDashboardComponent } from '../components/user-dashboard/user-dashboard.component';
import { authGuardFn } from '../guards/auth.guard';
import { ErrorPageComponent } from '../components/error-page/error-page.component';
import { UserGamePageComponent } from '../components/user-game-page/user-game-page.component';
import { UserNavComponent } from '../components/user-nav/user-nav.component';
import {
  accountRouteResolveData,
  myGamesRouteResolveData,
  paymentRouteResolveData,
  userDashboardRouteResolveData,
  userGameRouteResolveData,
} from './resolvers';
import { PricingPageComponent } from '../components/pricing-page/pricing-page.component';
import { AccountPageComponent } from '../components/account-page/account-page.component';
import { PaymentPageComponent } from '../components/payment-page/payment-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: PathParts.mygames, pathMatch: 'full' },
      { path: PathParts.error, component: ErrorPageComponent },
      {
        path: PathParts.login,
        component: LoginComponent,
        canActivate: [authGuardFn('unauth')],
      },
      { path: PathParts.pricing, component: PricingPageComponent },
      {
        path: PathParts.mygames,
        children: [
          {
            path: '',
            component: UserNavComponent,
            resolve: myGamesRouteResolveData,
            children: [
              { path: '', component: UserDashboardComponent, resolve: userDashboardRouteResolveData },
              {
                path: PathParts.game,
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    redirectTo: `/${PathParts.mygames}`,
                  },
                  {
                    path: `:${PathParams.userGameId}`,
                    component: UserGamePageComponent,
                    resolve: userGameRouteResolveData,
                  },
                ],
              },
              {
                path: PathParts.account,
                children: [
                  {
                    path: '',
                    component: AccountPageComponent,
                    resolve: accountRouteResolveData,
                  },
                ],
                canActivate: [authGuardFn('auth')],
              },
              {
                path: PathParts.payment,
                component: PaymentPageComponent,
                resolve: paymentRouteResolveData,
                canActivate: [authGuardFn('auth')],
              },
            ],
          },
        ],
        canActivate: [authGuardFn('auth')],
      },
    ],
  },
  { path: '**', redirectTo: PathParts.mygames },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true, //
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
