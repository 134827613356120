import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewGameDialogComponent, NewGameDialogData } from '../../dialogs/new-game-dialog/new-game-dialog.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserGame, UserGameState } from '../../models/user-game';
import { Game } from '../../models/game';
import { naturalSortObjectsByPropertyFn } from '../../util/sort';
import { map } from 'rxjs/operators';
import { ModelState } from '@kto/modelled-api-store-service';
import { ActivatedRoute } from '@angular/router';
import { UserDashboardRouteData } from '../../routing/lib';
import { trackUserGame } from '../../util/track-by';
import { isRequiredStoredModelledItem } from '../../util/identity';

const userGameNameSorter = naturalSortObjectsByPropertyFn('name');

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent implements OnInit, OnDestroy {
  userGames$: Observable<ModelState<UserGame>>;
  games$: Observable<ModelState<Game>>;

  ownerId: string;
  private ngUnsubscribe = new Subject<void>();

  protected readonly trackUserGame = trackUserGame;

  sortedUserGames$: Observable<UserGame[]>;

  constructor(public dialog: MatDialog, private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    (this.route.data as Observable<UserDashboardRouteData>).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.games$ = data.games$;
      this.userGames$ = data.userGames$;
      this.ownerId = data.ownerId;
    });

    this.sortedUserGames$ = this.userGames$.pipe(
      map((userGamesModelState) => {
        const userGames = Object.values(userGamesModelState)
          .filter(isRequiredStoredModelledItem)
          .map((userGame) => userGame.item)
          .sort(userGameNameSorter);

        const runningGames: UserGame[] = [];
        const idleGames: UserGame[] = [];

        userGames.forEach((userGame) =>
          userGame.state && userGame.state > UserGameState.INSTANCE_OFFLINE
            ? runningGames.push(userGame)
            : idleGames.push(userGame)
        );

        return [...runningGames, ...idleGames];
      })
    );
  }

  openNewGameDialog(): void {
    const initialData: NewGameDialogData = {
      games$: this.games$,
      ownerId: this.ownerId,
    };
    const dialogRef = this.dialog.open(NewGameDialogComponent, {
      data: initialData,
      disableClose: true,
      panelClass: 'themed-overlay-color',
    });

    dialogRef
      .afterClosed()
      .subscribe((_result) => {});
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
