import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable()
export class TermsAcceptanceInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 497) {
          this.userService.openTermsAcceptanceDialog();
          return of(new HttpResponse({ status: 100, body: { data: [] } }));
        } else {
          throw error;
        }
      })
    );
  }
}
