import { Component, OnInit } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { UserGame } from '../../models/user-game';
import { ActivatedRoute } from '@angular/router';
import { UserGameRouteData } from '../../routing/lib';
import { GameConfigItem } from '../../interfaces/game-config-item';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-game-page',
  templateUrl: './user-game-page.component.html',
  styleUrls: ['./user-game-page.component.scss'],
})
export class UserGamePageComponent implements OnInit {
  userGame$: Observable<UserGame>;
  defaultConfigItems$: Observable<GameConfigItem[]>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const routeData$ = this.route.data as Observable<UserGameRouteData>;
    this.userGame$ = routeData$.pipe(switchMap(({ userGame$ }) => userGame$));
    this.defaultConfigItems$ = routeData$.pipe(map(({ defaultGameConfigItems }) => defaultGameConfigItems));
  }
}
