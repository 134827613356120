import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PathParts } from '../routing/lib';
import { UserService } from '../services/user.service';
import { map } from 'rxjs/operators';

export const authGuardFn: (authOrUnauth: 'auth' | 'unauth') => CanActivateFn = (authOrUnauth) => {
  return () => {
    const userService = inject(UserService);
    const router = inject(Router);

    return userService.isAuthenticated$.pipe(
      map((isAuthenticated) => {
        switch (authOrUnauth) {
          case 'auth':
            return isAuthenticated || router.createUrlTree([PathParts.login]);
          case 'unauth':
            return !isAuthenticated || router.createUrlTree([PathParts.mygames]);
        }
      })
    );
  };
};
