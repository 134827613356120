import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { MyGamesRouteData, UserGames$ } from '../lib';
import { inject } from '@angular/core';
import { AppApiService } from '../../services/app-api.service';
import { of } from 'rxjs';

export const userGames$Resolver: ResolveFn<UserGames$> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const appApiService = inject(AppApiService);
  const ownerId = (route.parent?.data as MyGamesRouteData).ownerId;

  if (!appApiService.userGamesState) {
    appApiService.listUserGames$(ownerId);
  }

  return of(appApiService.userGamesByOwnerId$(ownerId));
};
