import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { delay, Subject, takeUntil, tap, throttleTime } from 'rxjs';

const THROTTLE_TIME = 5000;

@Component({
  selector: 'app-throttled-button',
  templateUrl: './throttled-button.component.html',
  styleUrls: ['./throttled-button.component.scss'],
})
export class ThrottledButtonComponent implements OnInit, OnDestroy {
  @Input() disable: boolean;
  @Input() throttleTime: number = THROTTLE_TIME;
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Output() release = new EventEmitter<void>();

  throttled = false;

  private ngUnsubscribe = new Subject<void>();
  private throttledSubject = new Subject<void>();

  get disableButton(): boolean {
    return this.disable || this.throttled;
  }

  ngOnInit(): void {
    const refresher$ = this.throttledSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      throttleTime(THROTTLE_TIME),
      tap(() => (this.throttled = true))
    );
    refresher$.pipe(delay(THROTTLE_TIME)).subscribe(() => (this.throttled = false));
    refresher$.subscribe(() => this.release.emit());
  }

  onClick(): void {
    this.throttledSubject.next();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.throttledSubject.complete();
  }
}
