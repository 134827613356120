<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Delete {{ userGame.name }} ?</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class='card-content dashboard-card-overflow dialog-content'>
    <p class="mat-body-1">
      <mat-icon aria-hidden="true" color='warn'>warning</mat-icon>
      This will <strong>DELETE</strong> all game data. Make sure you have backed up <strong>ALL</strong> files you want
      to keep from the server. We do not keep any backups of user game data, and there will be <strong>NO</strong> way
      to recover your files after you delete the game.
    </p>
    <mat-form-field appearance="outline" color="accent" [style.width.%]="100">
      <mat-label>
        Type <code>{{ DELETION_CONFIRMATION_STRING }}</code> in the field below to confirm you want to delete
        {{ userGame.name }}
      </mat-label>
      <input matInput [formControl]="confirmationInput" />
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-flat-button color="warn" [disabled]="!confirmationInput.valid" [mat-dialog-close]="true">
      Yes, DELETE {{ userGame.name }}
    </button>
    <button mat-flat-button color="accent" [mat-dialog-close]="false">Cancel</button>
  </mat-card-actions>
</mat-card>
