import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PathParts } from '../../../routing/lib';
import { UiOp, UiStateService } from '../../../services/ui-state.service';
import { UserService } from '../../../services/user.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    public uiStateService: UiStateService,
    private userService: UserService
  ) {
    this.userService.authState$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (authState) => {
      this.uiStateService.setGlobalSpinnerActive(UiOp.LOGIN, authState === 'pending');
      if (authState === 'authenticated') {
        await this.router.navigate([PathParts.mygames]);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
