export const ACTIVE_POLLING_INTERVAL_MS = 5 * 1000;
export const PASSIVE_POLLING_INTERVAL_MS = 60 * 1000;
export const BACKOFF_INITIAL_INTERVAL = 2000;
export const BACKOFF_EXPONENT = 2;

export const getExponentialBackoffDelay = (props: {
  initialInterval?: number;
  exponent?: number;
  attempt: number;
}): number => {
  const { initialInterval, attempt, exponent } = props;
  const _initialInterval = initialInterval ?? BACKOFF_INITIAL_INTERVAL;
  const _exponent = exponent ?? BACKOFF_EXPONENT;
  return Math.pow(_exponent, attempt - 1) * _initialInterval;
};

export const getExponentialBackoffMaxCount = (
  props: {
    initialInterval?: number;
    exponent?: number;
    thresholdTime?: number;
  } = Object.freeze({})
): number => {
  const { initialInterval, exponent, thresholdTime } = props;
  const _initialInterval = initialInterval ?? BACKOFF_INITIAL_INTERVAL;
  const _exponent = exponent ?? BACKOFF_EXPONENT;
  const _thresholdTime = thresholdTime ?? PASSIVE_POLLING_INTERVAL_MS;

  let cumulativeDelay = _initialInterval;
  let retryCount = 0;

  while (cumulativeDelay <= _thresholdTime) {
    const currentBackoff = Math.pow(_exponent, retryCount) * _initialInterval;
    retryCount += 1;
    cumulativeDelay += currentBackoff;
  }

  return retryCount;
};
