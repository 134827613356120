import { GameShortName } from './enums';

export const ACTIVE_GAMES_MAP: { [key in GameShortName]: boolean } = {
  [GameShortName.TF2]: false,
  [GameShortName.EMPYRION]: false,
  [GameShortName.TERRARIA]: false,
  [GameShortName.SATISFACTORY]: false,
  [GameShortName.VALHEIM]: true,
  [GameShortName.ENSHROUDED]: true,
  [GameShortName.COREKEEPER]: true,
  [GameShortName['7D2D']]: true,
};
