import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-stripe-payment-error',
  templateUrl: './stripe-payment-error.component.html',
  styleUrls: ['./stripe-payment-error.component.scss'],
})
export class StripePaymentErrorComponent {
  @Input() paymentError: HttpErrorResponse;

  get isClientError(): boolean {
    return this.paymentError.status >= 400 && this.paymentError.status < 500;
  }

  get stripeErrorMessage(): string {
    return (this.paymentError.error as { error: string }).error;
  }
}
