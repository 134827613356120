import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGameService } from '../services/user-game.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'userGameIdToUserGameName$',
})
export class UserGameIdToUserGameName$Pipe implements PipeTransform {
  private readonly userGameName$Store: { [userGameId: string]: Observable<string> } = {};

  constructor(private userGameService: UserGameService) {}

  transform(userGameId: string, ..._args: unknown[]): Observable<string> {
    if (!this.userGameName$Store[userGameId]) {
      this.userGameName$Store[userGameId] = this.userGameService.getUserGame$(userGameId).pipe(map(({ name }) => name));
    }
    return this.userGameName$Store[userGameId];
  }
}
