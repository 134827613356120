import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { GameConfigItem } from '../../interfaces/game-config-item';
import { inject } from '@angular/core';
import { GameConfigsService } from '../../services/game-configs.service';
import { PathParams, PathParts } from '../lib';

export const defaultGameConfigItemsResolver: ResolveFn<GameConfigItem[]> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const gameConfigsService = inject(GameConfigsService);
  const router = inject(Router);

  const userGameId = route.paramMap.get(PathParams.userGameId);
  if (!userGameId) {
    router.navigate([PathParts.error]).then();
    const error = new Error(`defaultGameConfigItemsResolver ran on path without ${PathParams.userGameId}`);
    console.error(error);
    throw error;
  }

  return gameConfigsService.getConfigItems$(userGameId);
};
