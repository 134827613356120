import { InstanceType } from './enums';

const instanceTypeLabels: { [k in InstanceType]: string } = {
  [InstanceType.SMALL]: 'Simple',
  [InstanceType.MEDIUM]: 'Basic',
  [InstanceType.LARGE]: 'Premium',
  [InstanceType.XLARGE]: 'Ultra',
};

export const InstanceTypeLabel = (instanceType: InstanceType): string => instanceTypeLabels[instanceType];

interface InstanceTypeOption {
  value: InstanceType;
  label: string;
}

export const INSTANCE_TYPE_OPTIONS: InstanceTypeOption[] = Object.values(InstanceType).map((instanceType) => ({
  value: instanceType,
  label: InstanceTypeLabel(instanceType),
}));
