import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserGame } from '../../models/user-game';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { getCurrentCognitoSub } from '../../util/auth';
import { AppApiService } from '../../services/app-api.service';
import { Game } from '../../models/game';
import { ModelState, StoredStatus } from '@kto/modelled-api-store-service';
import { naturalSortObjectsByPropertyFn } from '../../util/sort';
import { NonNullFormValue, TypedModelledFormGroup } from '../../util/forms';
import { RegionConfigName } from '../../models/region-config';
import { map } from 'rxjs/operators';
import { listRequiredModelledItem, modelledListIdsUnchanged } from '../../util/rxjs-pipes';
import { classOptions, UserGameClass } from '../../lib/user-game-class';

export interface NewGameDialogData {
  games$: Observable<ModelState<Game>>;
  ownerId: string;
}

@Component({
  selector: 'app-new-game-dialog',
  templateUrl: './new-game-dialog.component.html',
  styleUrls: ['./new-game-dialog.component.scss'],
})
export class NewGameDialogComponent implements OnInit, OnDestroy {
  games$: Observable<ModelState<Game>>;
  newUserGameForm: TypedModelledFormGroup<UserGame, 'name' | 'class' | 'instanceRegion' | 'gameId'>;
  classOptions = classOptions;
  ownerId: string;
  sortedGamesList$: Observable<Game[]>;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private appApiService: AppApiService,
    @Optional() public dialogRef: MatDialogRef<NewGameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewGameDialogData
  ) {}

  async ngOnInit(): Promise<void> {
    this.ownerId = await getCurrentCognitoSub();
    this.games$ = this.data.games$;
    this.ownerId = this.data.ownerId;
    this.createNewGameForm();

    this.sortedGamesList$ = this.games$.pipe(
      listRequiredModelledItem(),
      modelledListIdsUnchanged(),
      map((games) => games.sort(naturalSortObjectsByPropertyFn('name')))
    );
  }

  async onCancelNewUserGame(): Promise<void> {
    this.dialogRef.close();
  }

  private createNewGameForm(): void {
    this.newUserGameForm = this.fb.group({
      name: new FormControl<string | null>(null, { validators: Validators.required }),
      class: new FormControl<UserGameClass | null>('Basic', { validators: Validators.required }),
      instanceRegion: new FormControl<RegionConfigName | null>(null, { validators: Validators.required }),
      gameId: new FormControl<string | null>(null, { validators: Validators.required }),
    });
  }

  get selectedGameId(): string | null {
    return this.newUserGameForm?.controls.gameId.value;
  }

  onSubmitNewUserGame(): void {
    if (this.newUserGameForm.valid) {
      this.newUserGameForm.disable();
      const formValue = this.newUserGameForm.value as NonNullFormValue<typeof this.newUserGameForm.value>;

      this.ngUnsubscribe.next();
      this.appApiService
        .createUserGame$({
          ...formValue,
          ownerId: this.ownerId,
        })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          tap((storedUserGame) => {
            if (storedUserGame.state.status == StoredStatus.ERROR) {
              throw new Error('Error Creating User Game');
            }
          })
        )
        .subscribe({
          next: (storedUserGame) => {
            if (storedUserGame.state.status == StoredStatus.UPDATED) {
              this.dialogRef.close();
              this.newUserGameForm.enable();
            }
          },
          error: () => {
            this.newUserGameForm.enable();
            // TODO: Error reporting
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
