import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { UserNavComponent } from './components/user-nav/user-nav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NewGameDialogComponent } from './dialogs/new-game-dialog/new-game-dialog.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardGameCardComponent } from './components/dashboard-game-card/dashboard-game-card.component';
import { environment } from 'src/environments/environment';
import { DATA_FIXTURES_DELAY_TOKEN, USE_DATA_FIXTURES_TOKEN } from './services/app-api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/auth/login/login.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { EVENT_API_URL } from './services/app-event-api.service';
import { GameConfigItemFormComponent } from './components/game-config-item-form/game-config-item-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { getHttpServiceFactoryProvider, Adapter } from '@kto/modelled-api-store-service';
import { GAMES_SERVERS_API_TOKEN } from './tokens';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { NgOptimizedImage } from '@angular/common';
import { ServerDetailsDialogComponent } from './dialogs/server-details-dialog/server-details-dialog.component';
import { ValhallaModel } from './models/lib';
import { GameDeleteDialogComponent } from './dialogs/game-delete-dialog/game-delete-dialog.component';
import { UserGameToUserGame$Pipe } from './pipes/user-game-to-user-game-$.pipe';
import { UserGameControlsComponent } from './components/user-game-controls/user-game-controls.component';
import { ShellComponent } from './components/shell/shell.component';
import { UserGameCountdownComponent } from './components/user-game-countdown/user-game-countdown.component';
import { ShellNavListItemComponent } from './components/shell/shell-nav-list-item/shell-nav-list-item.component';
import { UserGamePageComponent } from './components/user-game-page/user-game-page.component';
import { UserGameFormComponent } from './components/user-game-form/user-game-form.component';
import { UserGameDeleteWrapperComponent } from './components/user-game-delete-wrapper/user-game-delete-wrapper.component';
import { PricingPageComponent } from './components/pricing-page/pricing-page.component';
import { PricingTableComponent } from './components/pricing-table/pricing-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { GameLogoDirective } from './directives/game-logo.directive';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component';
import { UserGameToGame$Pipe } from './pipes/user-game-to-game-$.pipe';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { PaymentPageComponent } from './components/payment-page/payment-page.component';
import { CreditDebitTableComponent } from './components/credit-debit-table/credit-debit-table.component';
import { UserGameIdToUserGameName$Pipe } from './pipes/user-game-id-to-user-game-name-$.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatStepperModule } from '@angular/material/stepper';
import { PoweredByStripeSpanComponent } from './components/powered-by-stripe-span/powered-by-stripe-span.component';
import { ProcessingPaymentDialogComponent } from './dialogs/processing-payment-dialog/processing-payment-dialog.component';
import { StripePaymentErrorComponent } from './dialogs/processing-payment-dialog/stripe-payment-error/stripe-payment-error.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { AmplifyPasswordFormComponent } from './components/amplify-password-form/amplify-password-form.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ThrottledButtonComponent } from './components/throttled-button/throttled-button.component';
import { BonusTableComponent } from './components/bonus-table/bonus-table.component';
import { TermsAcceptanceDialogComponent } from './dialogs/terms-acceptance-dialog/terms-acceptance-dialog.component';
import { TermsAcceptanceInterceptor } from './interceptors/terms-acceptance.interceptor';

const modelAdapters: { [modelName: string]: Adapter<any, any> } = {};
Object.values(ValhallaModel).forEach(
  (modelName) => (modelAdapters[modelName] = (apiData: { data: object }) => apiData.data)
);

@NgModule({
  declarations: [
    AppComponent,
    UserDashboardComponent,
    NewGameDialogComponent,
    DashboardGameCardComponent,
    LoginComponent,
    GameConfigItemFormComponent,
    ErrorPageComponent,
    ServerDetailsDialogComponent,
    UserNavComponent,
    GameDeleteDialogComponent,
    UserGameToUserGame$Pipe,
    UserGameControlsComponent,
    ShellComponent,
    UserGameCountdownComponent,
    ShellNavListItemComponent,
    UserGamePageComponent,
    UserGameFormComponent,
    UserGameDeleteWrapperComponent,
    PricingPageComponent,
    PricingTableComponent,
    GameLogoDirective,
    ErrorDialogComponent,
    UserGameToGame$Pipe,
    RegionSelectorComponent,
    AccountPageComponent,
    PaymentPageComponent,
    CreditDebitTableComponent,
    UserGameIdToUserGameName$Pipe,
    PoweredByStripeSpanComponent,
    ProcessingPaymentDialogComponent,
    StripePaymentErrorComponent,
    BackButtonComponent,
    AmplifyPasswordFormComponent,
    ThrottledButtonComponent,
    BonusTableComponent,
    TermsAcceptanceDialogComponent,
  ],
  imports: [
    AmplifyAuthenticatorModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgOptimizedImage,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatStepperModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    {
      provide: USE_DATA_FIXTURES_TOKEN,
      useValue: environment.fixtures.enable,
    },
    {
      provide: DATA_FIXTURES_DELAY_TOKEN,
      useValue: environment.fixtures.delay,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TermsAcceptanceInterceptor,
      multi: true,
    },
    {
      provide: EVENT_API_URL,
      useValue: environment.eventApi.url,
    },
    getHttpServiceFactoryProvider(GAMES_SERVERS_API_TOKEN, {
      apiUrl: environment.api.url,
      defaultIdProp: '__id',
      options: {
        adapters: modelAdapters,
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
