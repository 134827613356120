import { Inject, Injectable } from '@angular/core';
import { finalize, map, Observable, Subscription, tap } from 'rxjs';
import { UserGame, UserGameState } from '../models/user-game';
import { Game } from '../models/game';
import { GAMES_SERVERS_API_TOKEN } from '../tokens';
import {
  HttpService,
  ModelledItem,
  ModelState,
  StoredModelledItem,
  StoredState,
  StoredStatus,
} from '@kto/modelled-api-store-service';
import { OwnerBalance } from '../models/owner-balance';
import { ValhallaModel } from '../models/lib';
import { OWNER_BALANCE_ID_SUFFIX } from '../lib/owner-balance';
import { RegionConfig } from '../models/region-config';
import { PathParts } from '../routing/lib';
import { UiOp, UiStateService } from './ui-state.service';
import { Router } from '@angular/router';
import { Credit } from '../models/credit';
import { Debit } from '../models/debit';
import { Payment } from '../models/payment';
import { UserProfile } from '../models/user-profile';
import { USER_PROFILE_ID_SUFFIX } from '../lib/user-profile';
import { ACTIVE_GAMES_MAP } from '../lib/games';
import { isRequiredStoredModelledItem } from '../util/identity';
import { InstanceType } from '../lib/enums';
import { UserGameClass } from '../lib/user-game-class';

const MUULTI_QUERY_PROPERTY_NAME_PARAM = 'queryPropertyName';
const MUULTI_QUERY_PROPERTY_VALUE_PARAM = 'queryPropertyValue';

export const USE_DATA_FIXTURES_TOKEN = 'USE_DATA_FIXTURES_TOKEN';
export const DATA_FIXTURES_DELAY_TOKEN = 'DATA_FIXTURES_DELAY_TOKEN';
export const TEST_USER_ID = 'some-user-id';

type ListItems<T extends ModelledItem> = { opState: StoredState; modelState$: Observable<ModelState<T>> };
type ListItems$<T extends ModelledItem> = Observable<ListItems<T>>;

// TODO: No longer needed if 'instanceType' has been removed
const instanceTypeToClassMap: { [key in InstanceType]: UserGameClass } = {
  Small: 'Mini',
  Medium: 'Basic',
  Large: 'Premium',
  Xlarge: 'Ultra',
};
const userGameInstanceTypeMigrationMapper = (userGame: UserGame): UserGame => {
  if (userGame.class) {
    return userGame;
  }
  if (userGame.instanceType) {
    return {
      ...userGame,
      class: instanceTypeToClassMap[userGame.instanceType],
    };
  } else {
    return {
      ...userGame,
      class: 'Basic',
    };
  }
};
const userGameStoredModelledItemInstanceTypeMigrationMapper = (
  storedUserGame: StoredModelledItem<UserGame>
): StoredModelledItem<UserGame> => ({
  ...storedUserGame,
  item: storedUserGame.item ? userGameInstanceTypeMigrationMapper(storedUserGame.item) : undefined,
});
const userGameModelStateMigrationMapper = (userGameModelState: ModelState<UserGame>): ModelState<UserGame> => {
  const updatedModelState: ModelState<UserGame> = {};
  Object.entries(userGameModelState).forEach(([key, state]) => {
    updatedModelState[key] = userGameStoredModelledItemInstanceTypeMigrationMapper(state);
  });
  return updatedModelState;
};

// noinspection JSUnusedGlobalSymbols
@Injectable({
  providedIn: 'root',
})
export class AppApiService {
  public apiUrl: string;
  public store$;

  private rootUiOpSubscriptions: { [uiOp: string]: Subscription } = {};
  private idUiOpSubscriptions: { [uiOp: string]: { [itemId: string]: Subscription } } = {};

  constructor(
    @Inject(GAMES_SERVERS_API_TOKEN) private gamesService: HttpService,
    private uiStateService: UiStateService,
    private router: Router
  ) {
    this.apiUrl = this.gamesService.apiUrl;
    this.store$ = this.gamesService.state$;
  }

  clearUserItemsFromStore(): void {
    const retainedModels = [ValhallaModel.Game, ValhallaModel.RegionConfig];
    const storeState = this.gamesService.state;

    Object.keys(ValhallaModel)
      .filter((modelName) => !retainedModels.some((retainedModel) => retainedModel === modelName))
      .forEach((modelName) => delete storeState[modelName]);

    this.gamesService.setState(storeState);
  }

  private createItem$<T extends ModelledItem>(props: {
    item: Partial<T>;
    modelName: string;
  }): Observable<StoredModelledItem<T>> {
    const { item, modelName } = props;
    return this.gamesService.createItem$<T>({
      httpOptions: {},
      item,
      modelName,
    });
  }

  private readItem$<T extends ModelledItem>(props: {
    modelName: string;
    itemId: string;
  }): Observable<StoredModelledItem<T>> {
    const { modelName, itemId } = props;
    return this.gamesService.readItem$<T>({
      httpOptions: {},
      itemId,
      modelName,
    });
  }

  private uiOpReadItem$<T extends ModelledItem>(props: {
    modelName: string;
    itemId: string;
    uiOp: UiOp;
  }): Observable<StoredModelledItem<T>> {
    const { modelName, itemId, uiOp } = props;
    const read$ = this.readItem$<T>(props);

    if (!this.idUiOpSubscriptions[uiOp]) {
      this.idUiOpSubscriptions[uiOp] = {};
    }

    if (!this.idUiOpSubscriptions[uiOp][itemId]) {
      this.idUiOpSubscriptions[uiOp][itemId] = read$
        .pipe(
          tap(({ state }) => {
            switch (state.status) {
              case StoredStatus.PENDING:
                this.uiStateService.setGlobalSpinnerActive(uiOp, true);
                break;
              case StoredStatus.ERROR:
                throw state.error ?? new Error(`Error reading ${modelName}: ${itemId}`);
            }
          }),
          finalize(() => this.uiStateService.setGlobalSpinnerActive(uiOp, false))
        )
        .subscribe({
          error: async (err) => {
            console.error(err);
            await this.router.navigate([PathParts.mygames]);
          },
        });
    }

    return read$;
  }

  private updateItem$<T extends ModelledItem>(props: {
    modelName: string;
    itemId: string;
    item: Partial<T>;
  }): Observable<StoredModelledItem<T>> {
    const { modelName, itemId, item } = props;
    return this.gamesService.updateItem$<T>({
      httpOptions: {},
      itemId,
      modelName,
      item,
    });
  }

  private deleteItem$<T extends ModelledItem>(props: {
    modelName: string;
    itemId: string;
  }): Observable<StoredModelledItem<T>> {
    const { modelName, itemId } = props;
    return this.gamesService.deleteItem$<T>({
      httpOptions: {},
      itemId,
      modelName,
    });
  }

  private listItems$<T extends ModelledItem>(props: {
    modelName: ValhallaModel;
    by?: { parentModel: ValhallaModel; parentId: string };
  }): ListItems$<T> {
    const { modelName, by } = props;
    const parentIdProperty = by ? getParentIdPropertyFromModel(by.parentModel) : undefined;
    return this.gamesService.listItems$<T>({
      httpOptions: {
        ...(by
          ? {
              params: {
                [MUULTI_QUERY_PROPERTY_NAME_PARAM]: parentIdProperty ? parentIdProperty : '',
                [MUULTI_QUERY_PROPERTY_VALUE_PARAM]: by ? by.parentId : '',
              },
            }
          : {}),
      },
      modelName,
    });
  }

  private uiOpListItem$<T extends ModelledItem>(props: {
    modelName: ValhallaModel;
    itemId?: string;
    uiOp: UiOp;
    by?: { parentModel: ValhallaModel; parentId: string };
  }): ListItems$<T> {
    const { uiOp, itemId, modelName } = props;
    const list$ = this.listItems$<T>(props);
    const tappedList$ = list$.pipe(
      tap(({ opState }) => {
        switch (opState.status) {
          case StoredStatus.PENDING:
            this.uiStateService.setGlobalSpinnerActive(uiOp, true);
            break;
          case StoredStatus.ERROR:
            throw opState.error ?? new Error(`Error listing ${modelName}`);
        }
      }),
      finalize(() => this.uiStateService.setGlobalSpinnerActive(uiOp, false))
    );

    if (itemId && !this.idUiOpSubscriptions[uiOp]) {
      this.idUiOpSubscriptions[uiOp] = {};
    }

    const subscriptionExists = itemId ? !!this.idUiOpSubscriptions[uiOp][itemId] : !!this.rootUiOpSubscriptions[uiOp];
    if (!subscriptionExists) {
      const subscription = tappedList$.subscribe({
        error: async (err) => {
          console.error(err);
          await this.router.navigate([PathParts.error]);
        },
      });
      itemId
        ? (this.idUiOpSubscriptions[uiOp][itemId] = subscription)
        : (this.rootUiOpSubscriptions[uiOp] = subscription);
    }

    return list$;
  }

  private listChildItems$<T extends ModelledItem>(props: {
    modelName: ValhallaModel;
    parentModel: ValhallaModel;
    parentId: string;
  }): ListItems$<T> {
    return this.listItems$<T>({ ...props, by: { ...props } });
  }

  uiOpListChildItems$<T extends ModelledItem>(props: {
    modelName: ValhallaModel;
    uiOp: UiOp;
    itemId?: string;
    parentModel: ValhallaModel;
    parentId: string;
  }): ListItems$<T> {
    return this.uiOpListItem$<T>({ ...props, by: { ...props } });
  }

  // Game
  get gamesState(): ModelState<Game> | undefined {
    return this.gamesService.state[ValhallaModel.Game];
  }
  get games$(): Observable<ModelState<Game>> {
    return this.gamesService.getModelState$<Game>({ modelName: ValhallaModel.Game }).pipe(
      map((gameModelState) => {
        const filteredStoredModelGames: ModelState<Game> = {};

        Object.values(gameModelState)
          .filter(isRequiredStoredModelledItem)
          .forEach((storedModelGame) => {
            const game = storedModelGame.item;
            if (ACTIVE_GAMES_MAP[game.shortName]) {
              filteredStoredModelGames[game.__id] = storedModelGame;
            }
          });

        return filteredStoredModelGames;
      })
    );
  }
  listGames$(): ListItems$<Game> {
    return this.uiOpListItem$({ modelName: ValhallaModel.Game, uiOp: UiOp.GAMES_LOAD });
  }
  game$(gameId: string): Observable<StoredModelledItem<Game>> {
    return this.gamesService.getStoredItem$<Game>({ modelName: ValhallaModel.Game, itemId: gameId });
  }

  // UserGame
  get userGamesState(): ModelState<UserGame> | undefined {
    const state = this.gamesService.state[ValhallaModel.UserGame];
    return state ? userGameModelStateMigrationMapper(state) : undefined;
  }
  userGames$(): Observable<ModelState<UserGame>> {
    return this.gamesService
      .getModelState$<UserGame>({ modelName: ValhallaModel.UserGame })
      .pipe(map(userGameModelStateMigrationMapper));
  }

  userGamesByOwnerId$(ownerId: string): Observable<ModelState<UserGame>> {
    return this.userGames$().pipe(
      map((modelState) => {
        const filteredModelState: ModelState<UserGame> = {};

        Object.values(modelState).forEach((storedModelItem) => {
          if (storedModelItem.item && storedModelItem.item.ownerId === ownerId) {
            filteredModelState[storedModelItem.item.__id] = storedModelItem;
          }
        });

        return filteredModelState;
      })
    );
  }

  userGame$(userGameId: string): Observable<StoredModelledItem<UserGame>> {
    return this.gamesService
      .getStoredItem$<UserGame>({ modelName: ValhallaModel.UserGame, itemId: userGameId })
      .pipe(map(userGameStoredModelledItemInstanceTypeMigrationMapper));
  }

  createUserGame$(userGame: Omit<UserGame, '__id'>): Observable<StoredModelledItem<UserGame>> {
    return this.createItem$<UserGame>({ modelName: ValhallaModel.UserGame, item: userGame });
  }

  readUserGame$(userGameId: string): Observable<StoredModelledItem<UserGame>> {
    return this.uiOpReadItem$<UserGame>({
      modelName: ValhallaModel.UserGame,
      itemId: userGameId,
      uiOp: UiOp.USER_GAME_LOAD,
    });
  }

  updateUserGame$(props: {
    userGameId: string;
    userGame: Partial<UserGame>;
  }): Observable<StoredModelledItem<UserGame>> {
    return this.updateItem$<UserGame>({
      modelName: ValhallaModel.UserGame,
      itemId: props.userGameId,
      item: props.userGame,
    });
  }

  deleteUserGame$(userGameId: string): Observable<StoredModelledItem<UserGame>> {
    return this.deleteItem$({ modelName: ValhallaModel.UserGame, itemId: userGameId });
  }

  listUserGames$(ownerId: string): ListItems$<UserGame> {
    return this.uiOpListChildItems$({
      modelName: ValhallaModel.UserGame,
      uiOp: UiOp.USER_GAMES_LOAD,
      parentId: ownerId,
      parentModel: ValhallaModel.Owner,
    });
  }

  setUserGameState(userGameId: string, userGameState: UserGameState): void {
    this.gamesService.patchState(userGameState, ValhallaModel.UserGame, userGameId, 'item', 'state');
  }

  // OwnerBalance
  ownerBalance$(ownerId: string): Observable<StoredModelledItem<OwnerBalance>> {
    return this.gamesService.getStoredItem$<OwnerBalance>({
      modelName: ValhallaModel.OwnerBalance,
      itemId: ownerId + OWNER_BALANCE_ID_SUFFIX,
    });
  }

  readOwnerBalance$(ownerId: string): Observable<StoredModelledItem<OwnerBalance>> {
    return this.readItem$<OwnerBalance>({
      modelName: ValhallaModel.OwnerBalance,
      itemId: ownerId + OWNER_BALANCE_ID_SUFFIX,
    });
  }

  // RegionConfig
  get regionConfigsState(): ModelState<RegionConfig> | undefined {
    return this.gamesService.state[ValhallaModel.RegionConfig];
  }

  get regionConfigs$(): Observable<ModelState<RegionConfig>> {
    return this.gamesService.getModelState$({ modelName: ValhallaModel.RegionConfig });
  }

  listRegionConfigs$(): ListItems$<RegionConfig> {
    return this.uiOpListItem$<RegionConfig>({
      modelName: ValhallaModel.RegionConfig,
      uiOp: UiOp.REGION_CONFIGS_LOAD,
    });
  }

  // Credit
  get credit$(): Observable<ModelState<Credit>> {
    return this.gamesService.getModelState$({ modelName: ValhallaModel.Credit });
  }

  listCredits$(ownerId: string): ListItems$<Credit> {
    return this.listChildItems$({
      modelName: ValhallaModel.Credit,
      parentId: ownerId,
      parentModel: ValhallaModel.Owner,
    });
  }

  // Debit
  get debit$(): Observable<ModelState<Debit>> {
    return this.gamesService.getModelState$({ modelName: ValhallaModel.Debit });
  }

  listDebits$(ownerId: string): ListItems$<Debit> {
    return this.listChildItems$({
      modelName: ValhallaModel.Debit,
      parentId: ownerId,
      parentModel: ValhallaModel.Owner,
    });
  }

  // Payment
  createPayment$(payment: Required<Omit<Payment, '__id' | 'externalId'>>): Observable<StoredModelledItem<Payment>> {
    return this.createItem$<Payment>({ modelName: ValhallaModel.Payment, item: payment });
  }

  // UserProfile
  readUserProfile$(ownerId: string): Observable<StoredModelledItem<UserProfile>> {
    return this.readItem$<UserProfile>({
      modelName: ValhallaModel.UserProfile,
      itemId: ownerId + USER_PROFILE_ID_SUFFIX,
    });
  }

  updateUserProfile$(props: {
    ownerId: string;
    userProfile: Partial<UserProfile>;
  }): Observable<StoredModelledItem<UserProfile>> {
    return this.updateItem$<UserProfile>({
      modelName: ValhallaModel.UserProfile,
      itemId: props.ownerId + USER_PROFILE_ID_SUFFIX,
      item: props.userProfile,
    });
  }
}
const getParentIdPropertyFromModel = (parentModel: ValhallaModel): string =>
  `${parentModel.charAt(0).toLowerCase()}${parentModel.slice(1)}Id`;
