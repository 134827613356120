<button
  mat-icon-button
  [disabled]="!serverCanStart || ((ownerBalanceAmount$ | async) || 0) <= 0"
  [attr.aria-label]="'Start Server'"
  (click)="startGameServer()"
  color="primary"
>
  <mat-icon aria-hidden="true">play_arrow</mat-icon>
</button>
<button
  mat-icon-button
  [disabled]="!serverCanStop"
  [attr.aria-label]="'Stop Server'"
  (click)="stopGameServer()"
  color="accent"
>
  <mat-icon aria-hidden="true">stop</mat-icon>
</button>
<button
  mat-icon-button
  aria-label="Restart server"
  [disabled]="!serverCanRestart"
  (click)="restartGameServer()"
  color="accent"
>
  <mat-icon aria-hidden="true">refresh</mat-icon>
</button>
<button
  *ngIf="showDelete"
  color="warn"
  mat-icon-button
  aria-label="Delete server"
  [disabled]="disableDelete"
  (click)="deleteClicked.emit()"
>
  <mat-icon>delete</mat-icon>
</button>
<ng-container *ngIf="showStateIndicator">
  <span [ngStyle]="{ padding: '0.5em' }">
    <mat-icon [ngClass]="userGameStateClass" aria-hidden="true"> lightbulb </mat-icon>
  </span>
</ng-container>
