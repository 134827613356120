import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserGame } from '../models/user-game';
import { AppApiService } from '../services/app-api.service';
import { Game } from '../models/game';
import { modelledItemOnly } from '../util/rxjs-pipes';

@Pipe({
  name: 'userGameToGame$',
})
export class UserGameToGame$Pipe implements PipeTransform {
  private readonly game$Store: { [gameId: string]: Observable<Game> };

  constructor(private appApiService: AppApiService) {
    this.game$Store = {};
  }

  transform(userGame: UserGame, ..._args: unknown[]): Observable<Game> {
    const { gameId } = userGame;
    if (!this.game$Store[gameId]) {
      this.game$Store[gameId] = this.appApiService.game$(gameId).pipe(modelledItemOnly());
    }
    return this.game$Store[gameId];
  }
}
