import { Observable } from 'rxjs';
import { ModelState } from '@kto/modelled-api-store-service';
import { Game } from '../models/game';
import { UserGame } from '../models/user-game';
import { ResolveFn } from '@angular/router';
import { GameConfigItem } from '../interfaces/game-config-item';
import { RegionConfig } from '../models/region-config';

export enum PathParts {
  root = '',
  mygames = 'app',
  game = 'game',
  login = 'login',
  error = 'error',
  account = 'account',
  pricing = 'pricing',
  payment = 'payment',
}

export enum PathParams {
  userGameId = 'userGameId',
}

export type Games$ = Observable<ModelState<Game>>;
export type UserGames$ = Observable<ModelState<UserGame>>;
export type RegionConfigs$ = Observable<ModelState<RegionConfig>>;

export type TypedRouteResolveData<T> = {
  [K in keyof T]: ResolveFn<T[K]>;
};

export interface MyGamesRouteData {
  ownerId: string;
  games$: Games$;
  regionConfigs$: RegionConfigs$;
}

export interface UserDashboardRouteData extends MyGamesRouteData {
  userGames$: UserGames$;
}

export interface UserGameRouteData extends MyGamesRouteData {
  userGame$: Observable<UserGame>;
  defaultGameConfigItems: GameConfigItem[];
}

export interface AccountRouteData {
  ownerId: string;
}

export interface PaymentRouteData {
  ownerId: string;
}
