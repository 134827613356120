<span [ngStyle]="{ padding: '0.5em', display: 'flex' }">
  <span [ngStyle]="{ flex: '1 1 auto' }" *ngIf="align === 'right' || align === 'center'"></span>
  <img
    [ngSrc]="'/assets/stripe/powered_by_stripe_' + brandColor + '.svg'"
    [width]="initializationWidth"
    [height]="height"
    alt="Powered by Stripe"
  />
  <span *ngIf="align === 'center'" [ngStyle]="{ flex: '1 1 auto' }"></span>
</span>
