<mat-card>
  <form [formGroup]="formGroup">
    <mat-card-content>
      <ng-container *ngFor="let configItem of configItems">
        <ng-container *ngIf="!configItem.hidden">
          <ng-container [ngSwitch]="configItem.inputType">
            <mat-form-field
              *ngSwitchCase="'text'"
              appearance="outline"
              subscriptSizing="dynamic"
              [ngStyle]="{ marginBottom: '1em', marginRight: '1em' }"
            >
              <mat-label>{{ configItem.title }}</mat-label>
              <input matInput formControlName="{{ configItem.name }}" />
              <mat-hint>{{ configItem.description }}</mat-hint>
            </mat-form-field>
            <mat-form-field
              *ngSwitchCase="'number'"
              appearance="outline"
              subscriptSizing="dynamic"
              [ngStyle]="{ marginBottom: '1em', marginRight: '1em' }"
            >
              <mat-label>{{ configItem.title }}</mat-label>
              <input matInput formControlName="{{ configItem.name }}" type='number'/>
              <mat-hint>{{ configItem.description }}</mat-hint>
            </mat-form-field>
            <div
              *ngSwitchCase="'checkbox'"
              [ngStyle]="{ display: 'flex', flexDirection: 'column', marginBottom: '1em', marginRight: '1em' }"
            >
              <mat-checkbox formControlName="{{ configItem.name }}">
                <mat-label>{{ configItem.title }}</mat-label>
              </mat-checkbox>
              <div
                [ngStyle]="{
                  padding: '0 1em 0 1em',
                  fontFamily: ' var(--mat-form-field-subscript-text-font)',
                  fontWeight: ' var(--mat-form-field-subscript-text-weight)',
                  fontSize: ' var(--mat-form-field-subscript-text-size)'
                }"
              >
                {{ configItem.description }}
              </div>
            </div>
            <ng-container *ngSwitchDefault></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
      <span>
        <button mat-raised-button color="primary" (click)="onSubmitGameConfig()">Save</button>
        <button mat-raised-button color="warn" (click)="onCancelGameConfig()">Close</button>
      </span>
    </mat-card-actions>
  </form>
</mat-card>
