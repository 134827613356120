import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { ActivatedRoute, Router } from '@angular/router';
import { PathParts, PaymentRouteData } from '../../routing/lib';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, merge, Observable, ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { getTopUpBonus } from '../../lib/pricing';
import { map, startWith } from 'rxjs/operators';
import { TokenResult } from '@stripe/stripe-js/types/stripe-js/stripe';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/card';
import { MatDialog } from '@angular/material/dialog';
import {
  ProcessingPaymentDialogComponent,
  ProcessingPaymentDialogInput,
} from '../../dialogs/processing-payment-dialog/processing-payment-dialog.component';
import { AppApiService } from '../../services/app-api.service';
import { PaymentProvider } from '../../models/payment';
import { StoredState, StoredStatus } from '@kto/modelled-api-store-service';
import { OwnerBalanceService } from '../../services/owner-balance.service';
import { Location } from '@angular/common';

const DEFAULT_INITIAL_AMOUNT = 5;
const MINIMUM_ACCEPTABLE_PAYMENT = 5;

interface ConfirmForm {
  confirmation: FormControl<boolean | null>;
}

interface AmountForm extends ConfirmForm {
  amount: FormControl<number | null>;
}

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  amountForm: FormGroup<AmountForm>;
  confirmPaymentForm: FormGroup<ConfirmForm>;

  stripeCardElementChange$ = new ReplaySubject<StripeCardElementChangeEvent>(1);
  stripeCardElementValid$ = this.stripeCardElementChange$.pipe(
    map(({ complete }) => complete),
    startWith(false),
    distinctUntilChanged()
  );

  paymentForm = new FormGroup<Record<string, never>>(
    {},
    {
      asyncValidators: (_formGroup) =>
        this.stripeCardElementValid$.pipe(map((isValid) => (isValid ? null : { stripeCardInvalid: !isValid }))),
    }
  );

  stripe: Stripe;
  cardElement: StripeCardElement;
  stripeToken: TokenResult | null = null;

  protected readonly MINIMUM_ACCEPTABLE_PAYMENT = MINIMUM_ACCEPTABLE_PAYMENT;

  private ownerId: string;
  private ngUnsubscribe = new Subject<void>();

  @ViewChild(MatStepper) matStepper: MatStepper;
  @ViewChild('confirmPaymentStep') confirmPaymentStep: MatStep;

  get currentAmount(): number {
    return this.amountForm.value.amount || 0;
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private appApiService: AppApiService,
    private route: ActivatedRoute,
    private ownerBalanceService: OwnerBalanceService,
    private location: Location
  ) {
    (this.route.data as Observable<PaymentRouteData>).pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ ownerId }) => {
      this.ownerId = ownerId;
    });

    this.amountForm = this.fb.group({
      amount: new FormControl<number>(DEFAULT_INITIAL_AMOUNT, [
        Validators.required,
        Validators.min(MINIMUM_ACCEPTABLE_PAYMENT),
      ]),
      confirmation: new FormControl<boolean>(false, [Validators.requiredTrue]),
    });

    this.confirmPaymentForm = this.fb.group({
      confirmation: new FormControl<boolean>(false, [Validators.requiredTrue]),
    });

    merge(this.amountForm.valueChanges, this.stripeCardElementChange$)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.confirmPaymentStep.reset();
      });
  }

  async ngOnInit(): Promise<void> {
    this.ownerBalanceService.readOwnerBalance();
    let loadedStripe: Stripe | null = null;
    try {
      loadedStripe = await loadStripe(environment.stripe.publishableKey);
    } catch (e) {
      console.error('Error loading Stripe', e);
    }

    if (!loadedStripe) {
      await this.router.navigate(['/', PathParts.error]);
      return;
    }

    this.stripe = loadedStripe;

    const elements = this.stripe.elements({ currency: 'cad' });

    this.cardElement = elements.create('card', {
      style: {
        base: {
          iconColor: '#fbf1c7',
          color: '#fbf1c7',
          fontWeight: '500',
          fontFamily: 'IBM Plex Mono',
          fontSize: '14px',
          fontSmoothing: 'antialiased',
          ':-webkit-autofill': {
            color: '#504945',
          },
          '::placeholder': {
            color: '#458588',
          },
        },
        invalid: {
          iconColor: '#fb4934',
          color: '#fb4934',
        },
      },
    });

    this.cardElement.mount('#payment-element');

    this.cardElement.on('change', async (event) => {
      this.paymentForm.markAsDirty();
      this.paymentForm.markAsTouched();
      this.stripeCardElementChange$.next(event);

      if (event.complete) {
        try {
          this.stripeToken = await this.stripe.createToken(this.cardElement);
        } catch (e) {
          console.error('Error getting token from Stripe', e);
          await this.router.navigate(['/', PathParts.error]);
        }
      } else {
        this.stripeToken = null;
      }
    });

    this.amountForm.controls.amount.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(() => this.amountForm.controls.confirmation.setValue(false))
      )
      .subscribe((amount) =>
        !amount || amount < 0
          ? this.amountForm.controls.amount.setValue(0, { emitEvent: false })
          : this.amountForm.controls.amount.setValue(Math.floor(amount), { emitEvent: false })
      );

    this.amountForm.controls.amount.statusChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) =>
        status === 'VALID'
          ? this.amountForm.controls.confirmation.enable({ emitEvent: false })
          : this.amountForm.controls.confirmation.disable({ emitEvent: false })
      );
  }

  private enableForms(state: boolean): void {
    [this.amountForm, this.paymentForm, this.confirmPaymentForm].forEach((form) =>
      state ? form.enable({ emitEvent: false }) : form.disable({ emitEvent: false })
    );
  }

  private resetConfirmations(): void {
    [this.amountForm.controls.confirmation, this.confirmPaymentForm].forEach((control) => control.reset());
  }

  onSubmitPayment(): void {
    this.enableForms(false);

    const amountInDollars = this.currentAmount;
    const stripeToken = this.stripeToken;
    const ownerId = this.ownerId;

    if (!ownerId || !amountInDollars || !stripeToken || stripeToken.error) {
      return;
    }

    const amount = amountInDollars * 100;

    const dialogRef = this.dialog.open<ProcessingPaymentDialogComponent, ProcessingPaymentDialogInput, StoredState>(
      ProcessingPaymentDialogComponent,
      {
        panelClass: 'themed-overlay-color',
        data: {
          createPayment$: this.appApiService.createPayment$({
            amount,
            provider: PaymentProvider.STRIPE_CHARGE,
            clientToken: stripeToken.token.id,
            ownerId,
          }),
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe(async (storedState) => {
      if (!storedState || storedState.status !== StoredStatus.UPDATED) {
        this.matStepper.selectedIndex = 0;
        this.resetConfirmations();
        this.enableForms(true);
      } else {
        await this.router.navigate(['/', PathParts.mygames]);
      }
    });
  }

  onCancel(): void {
    this.location.back();
  }

  refreshBalance(): void {
    this.ownerBalanceService.readOwnerBalance();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.stripeCardElementChange$.complete();
    this.cardElement.destroy();
  }

  protected readonly getTopUpBonus = getTopUpBonus;
  protected readonly PathParts = PathParts;
}
