<div [ngStyle]="{ display: 'flex' }">
  <span class="l-r-spacer"> </span>
  <mat-card class="card">
    <app-back-button></app-back-button>
    <mat-card-header>
      <mat-card-title> How it Works </mat-card-title>
    </mat-card-header>
    <mat-card-content [ngStyle]="{ fontFamily: 'IBM Plex Mono', fontSize: 'larger', fontWeight: 'lighter' }">
      <mat-divider></mat-divider>
      <br />
      <mat-card-title> Only pay for what you use! </mat-card-title>
      <p>
        There's no doubt that most gamers would love to be able to never eat, sleep, or work, and just play games all
        the time, but few of us are able to do that. We started ValhallaGuru because we ourselves only ever had an odd
        weekend here or there where we had a chance to game with our friends.
      </p>

      <blockquote>
        <p>
          When you put credit in your account, it never expires. Also, the more you put in, the more bonus top-up credit
          you get!
        </p>
        <p>You can leave your games sitting idle and ready to play when <strong>YOU</strong> have the time.</p>
      </blockquote>

      <p>
        A keep-warm fee of <span [ngStyle]="{ textDecoration: 'line-through' }">$1 / month</span> per game* is deducted
        from your credit.

        <strong>Limited time launch celebration:</strong> Keep-warm fees are waived!
      </p>
      <p>
        After that, you are only deducted a small amount from your credit while a game is active. We deduct from your
        credit by the minute, so you don't have to worry about logging off just in time to make a cutoff.
      </p>

      <blockquote>
        <p>
          You can only ever pay what you put in. If your credit runs out, your games shutdown. That's it**. You won't
          keep getting a charge on your credit card every month.
        </p>
      </blockquote>
      <mat-divider></mat-divider>
      <br />

      <mat-card-title> Pricing (USD) </mat-card-title>
      <br />
      <app-pricing-table></app-pricing-table>
      <br />
      <br />

      <mat-card>
        <mat-card-content>
          <mat-card-title> Bonus! </mat-card-title>
          <mat-divider></mat-divider>
          <span [ngStyle]="{ display: 'flex' }">
            <span [ngStyle]="{ flex: '1 1 auto' }"></span>
            <app-bonus-table></app-bonus-table>
            <span [ngStyle]="{ flex: '1 1 auto' }"></span>
          </span>
        </mat-card-content>
      </mat-card>
      <br />
      <br />

      <mat-divider></mat-divider>
      <br />
      <mat-card-title> Server Types </mat-card-title>
      <p>
        With our Server Types, our goal is to give you a balance between flexibility and simplicity. We don't want to
        dictate how much power you want to provision to run your game server, or how much you want to spend.
      </p>
      <p>
        This is why we offer a range of classes and prices for servers that you can change at any time. Don't mind a bit
        of lag because money's tight right now? Go ahead and lower your provisioned Server Type. Got a free weekend and
        you're ready to have a massive game-fest with your friends? Go ahead and give it all the power!
      </p>
      <p>
        We've outlined below some rough descriptions of what each tier is capable of. We encourage you to use these as
        guidelines, but there are so many variables (the game you're running, how many players you have connecting, how
        big your world is, etc.) that we encourage you to play around and see what works for your preference and budget.
      </p>

      <table>
        <tr>
          <th>Server Type</th>
          <th>What you might use it for:</th>
        </tr>
        <tr>
          <td>
            {{ InstanceTypeLabel(InstanceType.SMALL) }}
          </td>
          <td>
            <ul>
              <li>One or two players</li>
              <li>Older games</li>
              <li>Just logging on to test things, tweak settings, etc.</li>
            </ul>
            <p>Single core, 2 GB of RAM.</p>
            <mat-divider></mat-divider>
          </td>
        </tr>
        <tr>
          <td>
            {{ InstanceTypeLabel(InstanceType.MEDIUM) }}
          </td>
          <td>
            <ul>
              <li>Ten or fewer players</li>
              <li>Most games with average sized worlds</li>
            </ul>
            <p>Dual core, 4 GB of RAM.</p>
            <mat-divider></mat-divider>
          </td>
        </tr>
        <tr>
          <td>
            {{ InstanceTypeLabel(InstanceType.LARGE) }}
          </td>
          <td>
            <ul>
              <li>10+ players</li>
              <li>Resource intensive games</li>
              <li>You don't like taking chances with performance, but don't want to break the bank</li>
            </ul>
            <p>Dual core, 16 GB of RAM.</p>
            <mat-divider></mat-divider>
          </td>
        </tr>
        <tr>
          <td>
            {{ InstanceTypeLabel(InstanceType.XLARGE) }}
          </td>
          <td>
            <ul>
              <li>Dozens of players</li>
              <li>Massive world files</li>
              <li>You like having the best possible performance, always</li>
            </ul>
            <p>Four cores, 16 GB of RAM.</p>
            <mat-divider></mat-divider>
          </td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-footer>
      <p>
        <small>
          * Keep-warm fees are prorated to midnight Eastern Time on the 1st of the calendar month on creation, and
          billed at that time afterwards
        </small>
      </p>
      <p>
        <small>
          ** ValhallaGuru reserves the right to delete all stored files and game content from accounts whose balance has
          been empty longer than 30 days.
        </small>
      </p>
    </mat-card-footer>
  </mat-card>
  <span class="l-r-spacer"></span>
</div>
