<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary" aria-hidden="true">error</mat-icon>
      Something went wrong...
    </mat-card-title>
    <mat-card-subtitle> Please reload the application. </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content> </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" (click)="reload()">RELOAD</button>
  </mat-card-actions>
</mat-card>
