<mat-card [ngStyle]="{ maxWidth: '400px' }">
  <ng-container *ngIf="createPayment$ | async as createPayment; else processingBlock">
    <ng-container *ngIf="createPayment.state.status < StoredStatus.PENDING; else processingBlock">
      <ng-container [ngSwitch]="createPayment.state.status">
        <ng-container *ngSwitchCase="StoredStatus.UPDATED">
          <ng-container *ngIf="createPayment.item as payment">
            <mat-card-header>
              <mat-card-title>Payment Complete!</mat-card-title>
              <mat-card-subtitle>Amount: {{ payment.amount / 100 | currency }}</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <p>You will see the following charge on your statement:</p>
              <p>
                {{ getCcStatementDescriptor(payment.__id) }}
              </p>
            </mat-card-content>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="StoredStatus.ERROR">
          <mat-card-header>
            <mat-card-title>Payment Incomplete</mat-card-title>
            <mat-card-subtitle>You have not been charged</mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>
            <app-stripe-payment-error [paymentError]="createPayment.state.error"></app-stripe-payment-error>
          </mat-card-content>
        </ng-container>
      </ng-container>
    </ng-container>

    <mat-card-actions>
      <button
        *ngIf="createPayment.state.status !== StoredStatus.PENDING"
        mat-flat-button
        color="primary"
        [mat-dialog-close]="createPayment.state"
      >
        Close
      </button>
    </mat-card-actions>
  </ng-container>
  <ng-template #processingBlock>
    <mat-card-header>
      <mat-card-title>Processing Payment...</mat-card-title>
      <mat-card-subtitle>This may take a moment</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <span [ngStyle]="{ display: 'flex' }">
        <span [ngStyle]="{ flex: '1 1 auto' }"></span>
        <mat-progress-spinner [diameter]="150" [mode]="'indeterminate'"></mat-progress-spinner>
        <span [ngStyle]="{ flex: '1 1 auto' }"></span>
      </span>
    </mat-card-content>
  </ng-template>

  <mat-card-footer>
    <app-powered-by-stripe-span></app-powered-by-stripe-span>
  </mat-card-footer>
</mat-card>
