import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Game } from '../../models/game';
import { UserGame } from '../../models/user-game';
import { getCurrentCognitoFederatedSub, getCurrentCognitoSub } from '../../util/auth';
import {
  ServerDetailsDialogComponent,
  ServerDetailsDialogInput,
} from '../../dialogs/server-details-dialog/server-details-dialog.component';
import { UserGameService } from '../../services/user-game.service';
import { UserGameStateTracker } from '../../lib/user-game-state-tracker';
import { Router } from '@angular/router';
import { PathParts } from '../../routing/lib';
import { REGION_CONFIG_NAME_MAP } from '../../models/region-config';

@Component({
  selector: 'app-dashboard-game-card',
  templateUrl: './dashboard-game-card.component.html',
  styleUrls: ['./dashboard-game-card.component.scss'],
})
export class DashboardGameCardComponent extends UserGameStateTracker implements OnInit, OnDestroy {
  @Input() userGame: UserGame;
  @Input() game: Game;

  ownerId: string;
  federatedOwnerId: string;

  private ngUnsubscribe = new Subject<void>();

  get humanReadableRegion(): string {
    return REGION_CONFIG_NAME_MAP[this.userGame.instanceRegion];
  }

  get userGameStateClass(): { [klass: string]: any } {
    return {
      'user-game-starting': this.serverIsStarting,
      'user-game-running': this.serverIsRunning,
      'user-game-restarting': this.serverIsRestarting,
      'user-game-stopping': this.serverIsStopping,
    };
  }

  constructor(public dialog: MatDialog, private userGameService: UserGameService, private router: Router) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.ownerId = await getCurrentCognitoSub();
    this.federatedOwnerId = await getCurrentCognitoFederatedSub();
  }

  async openGameConfig(): Promise<void> {
    await this.router.navigate([PathParts.mygames, PathParts.game, this.userGame.__id]);
  }

  async openServerDetails(): Promise<void> {
    const dialogConfig: ServerDetailsDialogInput = {
      userGame$: this.userGameService.getUserGame$(this.userGame.__id),
    };

    this.dialog.open(ServerDetailsDialogComponent, {
      data: dialogConfig,
      disableClose: false,
      panelClass: 'themed-overlay-color',
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
