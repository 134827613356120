<h1 [ngStyle]="{ marginLeft: '1em' }">My Account</h1>
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Security </mat-panel-title>
      <mat-panel-description>Change Password</mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-amplify-password-form></app-amplify-password-form>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Usage & Credit Details </mat-panel-title>
      <mat-panel-description>
        <button mat-flat-button color="accent" [routerLink]="['/', PathParts.mygames, PathParts.payment]">
          <mat-icon aria-hidden="true">payments</mat-icon>
          <span>Add Credit</span>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <app-credit-debit-table></app-credit-debit-table>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
