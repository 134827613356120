import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AppApiService } from './app-api.service';
import {
  distinctUntilChanged,
  filter,
  interval,
  Observable,
  of,
  retry,
  scan,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { OwnerBalance } from '../models/owner-balance';
import { UserGame, UserGameState } from '../models/user-game';
import { PASSIVE_POLLING_INTERVAL_MS, getExponentialBackoffMaxCount, getExponentialBackoffDelay } from '../lib/polling';
import { modelledItemOnly } from '../util/rxjs-pipes';
import { isRequiredStoredModelledItem } from '../util/identity';
import { StoredStatus } from '@kto/modelled-api-store-service';
import { getClassMinuteCost } from '../lib/user-game-class';

@Injectable({
  providedIn: 'root',
})
export class OwnerBalanceService {
  estimatedMinutesRemaining$: Observable<number | undefined>;
  ownerBalance$: Observable<OwnerBalance | undefined>;
  ownerBalanceAmount$: Observable<number>;
  estimatedCountdown$: Observable<string>;
  aggregateCostPerMinute$: Observable<number>;
  anyInstanceIsActive$: Observable<boolean>;

  private pollingUnsubscribe = new Subject<void>();

  constructor(private userService: UserService, private appApiService: AppApiService) {
    const ownerId$ = this.userService.ownerId$;

    ownerId$.subscribe((ownerId) => {
      if (ownerId) {
        // Initial Load - Exponential backoff until first polling interval
        this.appApiService
          .readOwnerBalance$(ownerId)
          .pipe(
            filter(({ state }) => state.status !== StoredStatus.PENDING),
            tap((storedOwnerBalance) => {
              if (storedOwnerBalance.state.status === StoredStatus.ERROR) {
                throw new Error('Error reading owner balance');
              }
            }),
            take(1),
            retry({
              count: getExponentialBackoffMaxCount(),
              delay: (_error, retryCount) => timer(getExponentialBackoffDelay({ attempt: retryCount })),
            })
          )
          .subscribe({
            error: () =>
              console.debug(
                `Unable to load initial balance within polling interval of ${PASSIVE_POLLING_INTERVAL_MS}ms. ` +
                  'Polling will continue normally'
              ),
          });

        // Polling
        interval(PASSIVE_POLLING_INTERVAL_MS)
          .pipe(takeUntil(this.pollingUnsubscribe))
          .subscribe(() => {
            this.appApiService.readOwnerBalance$(ownerId);
          });
      } else {
        this.pollingUnsubscribe.next();
      }
    });

    this.ownerBalance$ = ownerId$.pipe(
      switchMap((ownerId) => {
        if (!ownerId) {
          return of(undefined);
        }

        return this.appApiService.ownerBalance$(ownerId).pipe(modelledItemOnly());
      })
    );

    this.ownerBalanceAmount$ = this.ownerBalance$.pipe(
      map((ownerBalance) => ownerBalance?.balance || 0),
      distinctUntilChanged()
    );

    const getOwnerUserGames$ = (ownerId: string): Observable<UserGame[]> =>
      this.appApiService.userGamesByOwnerId$(ownerId).pipe(
        map((storedUserGames) =>
          Object.values(storedUserGames)
            .filter(isRequiredStoredModelledItem)
            .map((storedUserGame) => storedUserGame.item)
        )
      );

    this.aggregateCostPerMinute$ = ownerId$.pipe(
      switchMap((ownerId) => {
        if (!ownerId) {
          return of(0);
        }

        return getOwnerUserGames$(ownerId).pipe(
          map((userGames) =>
            userGames
              .filter((userGame) => !!userGame.instanceId)
              .reduce((aggregateCostPerMinute, currentUserGame) => {
                const currentUserGameCostPerMinute = getClassMinuteCost(currentUserGame.class);
                return currentUserGameCostPerMinute + aggregateCostPerMinute;
              }, 0)
          )
        );
      }),
      distinctUntilChanged()
    );

    this.anyInstanceIsActive$ = ownerId$.pipe(
      switchMap((ownerId) => {
        if (!ownerId) {
          return of(false);
        }
        return getOwnerUserGames$(ownerId).pipe(
          map((userGames) => userGames.some((userGame) => !!userGame.instanceId))
        );
      }),
      distinctUntilChanged()
    );

    this.estimatedMinutesRemaining$ = this.aggregateCostPerMinute$.pipe(
      switchMap((aggregateCostPerMinute) =>
        this.ownerBalanceAmount$.pipe(
          map((amount) => {
            const minutesRemaining = amount / aggregateCostPerMinute;
            return Math.max(0, minutesRemaining);
          })
        )
      ),
      distinctUntilChanged()
    );

    this.estimatedCountdown$ = this.estimatedMinutesRemaining$.pipe(
      switchMap((estimatedMinutesRemaining) =>
        this.anyInstanceIsActive$.pipe(switchMap((active) => getCountdownString$(estimatedMinutesRemaining, active)))
      )
    );
  }

  readOwnerBalance(): void {
    const ownerAttributes = this.userService.state.user?.attributes;
    if (ownerAttributes && ownerAttributes['sub']) {
      this.appApiService.readOwnerBalance$(ownerAttributes['sub']);
    }
  }

  getUserGameAggregateCostPerMinute$(userGameId: string): Observable<number> {
    return this.appApiService.userGame$(userGameId).pipe(
      modelledItemOnly(),
      distinctUntilChanged(
        (
          { instanceType: previousInstanceType, state: previousState },
          { instanceType: currentInstanceType, state: currentState }
        ) => previousInstanceType === currentInstanceType && previousState === currentState
      ),
      switchMap((userGame) =>
        userGame.state && userGame.state > UserGameState.INSTANCE_OFFLINE
          ? this.aggregateCostPerMinute$
          : this.aggregateCostPerMinute$.pipe(
              map((aggregateCostPerMinute) => {
                return aggregateCostPerMinute + getClassMinuteCost(userGame.class);
              })
            )
      )
    );
  }

  getUserGameMinutesRemaining$(userGameId: string): Observable<number> {
    return this.getUserGameAggregateCostPerMinute$(userGameId).pipe(
      switchMap((costPerMinute) =>
        this.ownerBalanceAmount$.pipe(
          map((ownerBalanceAmount) => {
            const minutesRemaining = ownerBalanceAmount / costPerMinute;
            return Math.max(0, minutesRemaining);
          })
        )
      )
    );
  }

  getUserGameCountdown$(userGameId: string): Observable<string> {
    return this.getUserGameMinutesRemaining$(userGameId).pipe(
      switchMap((userGameMinutesRemaining) =>
        this.anyInstanceIsActive$.pipe(switchMap((active) => getCountdownString$(userGameMinutesRemaining, active)))
      )
    );
  }
}

const SECONDS_MOD = 60;
const MINUTES_MOD = SECONDS_MOD * 60;
const HOURS_MOD = MINUTES_MOD * 60;

const formatLeadingZero = (aNumber: number): string => {
  const absNumber = Math.abs(aNumber);
  return absNumber >= 10 ? `${absNumber}` : `0${absNumber}`;
};

const getCountdownString = (secondsRemaining: number): string => {
  const seconds = secondsRemaining % SECONDS_MOD;
  const rounder = secondsRemaining >= 0 ? Math.floor : Math.ceil;
  const minutes = rounder((secondsRemaining % MINUTES_MOD) / SECONDS_MOD);
  const hours = rounder((secondsRemaining % HOURS_MOD) / MINUTES_MOD);

  return (
    (secondsRemaining < 0 ? '-' : '') +
    `${formatLeadingZero(hours)}:${formatLeadingZero(minutes)}:${formatLeadingZero(seconds)}`
  );
};

const getCountdownString$ = (minutesRemaining: number | undefined, active: boolean) => {
  if (!minutesRemaining || minutesRemaining === Infinity) {
    return of('--:--:--');
  }
  const estimatedSecondsRemaining = Math.floor(minutesRemaining * 60);

  return active
    ? timer(0, 1000).pipe(
        scan((acc) => acc - 1, estimatedSecondsRemaining),
        map((accumulatedSecondsRemaining) => getCountdownString(accumulatedSecondsRemaining))
      )
    : of(getCountdownString(estimatedSecondsRemaining));
};
