import {
  AccountRouteData,
  MyGamesRouteData,
  PaymentRouteData,
  TypedRouteResolveData,
  UserDashboardRouteData,
  UserGameRouteData,
} from '../lib';
import { userIdResolver } from './user-id';
import { games$Resolver } from './games';
import { userGames$Resolver } from './user-games';
import { userGame$Resolver } from './user-game';
import { defaultGameConfigItemsResolver } from './game-config-items';
import { regionConfigs$Resolver } from './region-configs';

export const myGamesRouteResolveData: TypedRouteResolveData<MyGamesRouteData> = {
  ownerId: userIdResolver,
  games$: games$Resolver,
  regionConfigs$: regionConfigs$Resolver,
};
export const userDashboardRouteResolveData: TypedRouteResolveData<UserDashboardRouteData> = {
  ...myGamesRouteResolveData,
  userGames$: userGames$Resolver,
};
export const userGameRouteResolveData: TypedRouteResolveData<UserGameRouteData> = {
  ...myGamesRouteResolveData,
  userGame$: userGame$Resolver,
  defaultGameConfigItems: defaultGameConfigItemsResolver,
};
export const accountRouteResolveData: TypedRouteResolveData<AccountRouteData> = {
  ownerId: userIdResolver,
};

export const paymentRouteResolveData: TypedRouteResolveData<PaymentRouteData> = {
  ownerId: userIdResolver,
};
