<div>
  <div [ngStyle]="{ margin: '0 1em 1em 1em', paddingTop: '15px' }">
    <div class="mat-toolbar-row">
      <h1 class="mat-h1">My Games</h1>
      <button
        mat-icon-button
        class="mat-accent"
        aria-label="Add Game"
        style="margin-bottom: 15px"
        (click)="openNewGameDialog()"
      >
        <mat-icon>control_point</mat-icon>
      </button>
    </div>

    <div class="grid">
      <ng-container *ngIf="sortedUserGames$ | async as userGames">
        <ng-container *ngFor="let userGame of userGames; trackBy: trackUserGame">
          <ng-container *ngIf="userGame | userGameToUserGame$ | async as currentUserGame">
            <ng-container *ngIf="userGame | userGameToGame$ | async as game">
              <app-dashboard-game-card
                class="container-content"
                [userGame]="currentUserGame"
                [game]="game"
              ></app-dashboard-game-card>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
