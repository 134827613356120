<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isSmall$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isSmall$ | async) ? 'over' : 'side'"
    [opened]="(isSmall$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="[RootPaths.pricing]"
        routerLinkActive
        [routerLinkActiveOptions]="{ exact: true }"
        #pricingLinkActiveInstance="routerLinkActive"
        [activated]="pricingLinkActiveInstance.isActive"
      >
        How it Works
      </a>

      <a
        mat-list-item
        [routerLink]="[RootPaths.mygames]"
        routerLinkActive
        [routerLinkActiveOptions]="{ exact: true }"
        #myGamesLinkActiveInstance="routerLinkActive"
        [activated]="myGamesLinkActiveInstance.isActive"
      >
        My Games
      </a>
      <ng-container *ngIf="userGames$ | async as userGames">
        <ng-container *ngIf="userGames.length">
          <mat-divider></mat-divider>
          <ng-container *ngFor="let userGame of userGames; trackBy: trackUserGame; last as isLast">
            <div *ngIf="userGame | userGameToUserGame$ | async as currentUserGame">
              <app-shell-nav-list-item [userGame]="currentUserGame" [isLast]="isLast"></app-shell-nav-list-item>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <mat-divider></mat-divider>
      <ng-container [ngSwitch]="isAuthenticated$ | async">
        <a *ngSwitchCase="true" mat-list-item (click)="signOut()"> Log Out </a>
        <a
          *ngSwitchCase="false"
          mat-list-item
          [routerLink]="[RootPaths.login]"
          routerLinkActive
          [routerLinkActiveOptions]="{ exact: true }"
          #loginLinkActiveInstance="routerLinkActive"
          [activated]="loginLinkActiveInstance.isActive"
        >
          Log In
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [ngStyle]="{ display: 'flex', flexDirection: 'column', height: 'inherit' }">
      <mat-toolbar color="primary" [ngStyle]="{ zIndex: '10' }">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isSmall$ | async"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <h1 style="margin-right: 5px" [routerLink]="['/', PathParts.mygames]" [ngStyle]="{ cursor: 'pointer' }">
          ValhallaGuru
        </h1>
        <section class="mat-typography">Beta</section>
      </mat-toolbar>
      <div [ngStyle]="{ flex: '1 0 auto' }">
        <router-outlet></router-outlet>
      </div>
      <mat-toolbar>
        <span [ngStyle]="{ fontSize: '8px' }">© 2023 Kontralto Inc.</span>
        <span [ngStyle]="{ flex: '1 1 auto' }"></span>
        <a mat-button href="https://terms.valhalla.guru/" rel="noopener noreferrer" target="_blank">
          Terms of Service
        </a>
      </mat-toolbar>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
