import { Component } from '@angular/core';
import { InstanceType } from '../../lib/enums';
import { InstanceTypeLabel } from '../../lib/instance-type';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss'],
})
export class PricingPageComponent {
  readonly InstanceType = InstanceType;
  protected readonly InstanceTypeLabel = InstanceTypeLabel;
}
