import { Injectable } from '@angular/core';
import { Store } from '@kto/rxjs-observable-store';
import { GameConfig, GameConfigItem } from '../interfaces/game-config-item';
import { AppApiService } from './app-api.service';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UiOp, UiStateService } from './ui-state.service';
import { PathParts } from '../routing/lib';
import { Storage } from 'aws-amplify';
import { filter, Observable } from 'rxjs';
import { modelledItemOnly } from '../util/rxjs-pipes';
import { isRequiredStoredModelledItem } from '../util/identity';

@Injectable({
  providedIn: 'root',
})
export class GameConfigsService extends Store<{ [gameId: string]: GameConfig }> {
  constructor(private appApiService: AppApiService, private router: Router, private uiStateService: UiStateService) {
    super({});
    this.appApiService.games$
      .pipe(
        map((gamesModelState) =>
          Object.values(gamesModelState)
            .filter(isRequiredStoredModelledItem)
            .map((storedGame) => storedGame.item)
        )
      )
      .subscribe(async (games) => {
        const newGames = games.filter(({ __id: gameId }) => !this.state[gameId]);
        if (newGames) {
          this.uiStateService.setGlobalSpinnerActive(UiOp.GAME_CONFIGS, true);
        }
        try {
          const gameConfigs = await Promise.all(
            newGames.map(async (game): Promise<[string, GameConfig]> => {
              // Download gameConfiguration's JSON schema from S3
              const { __id: gameId, shortName } = game;
              const url = `${shortName}/config/configItems.json`;
              const existingGameConfig = await Storage.vault.get(url, {
                download: true,
                level: 'public',
                cacheControl: 'no-cache',
              });
              let gameConfig: GameConfig;
              if (existingGameConfig && existingGameConfig.Body) {
                const arrayBuffer = await (existingGameConfig.Body as Blob).arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);

                const textDecoder = new TextDecoder('utf-8');
                gameConfig = JSON.parse(textDecoder.decode(uint8Array));
              } else {
                gameConfig = { configItems: [] };
              }
              return [gameId, gameConfig];
            })
          );
          gameConfigs.forEach(([gameId, gameConfig]) => this.patchState(gameConfig, gameId));
        } catch (err) {
          console.error(err);
          await this.router.navigate([PathParts.error]);
        } finally {
          this.uiStateService.setGlobalSpinnerActive(UiOp.GAME_CONFIGS, false);
        }
      });
  }

  getConfigItems$(userGameId: string): Observable<GameConfigItem[]> {
    return this.appApiService.userGame$(userGameId).pipe(
      modelledItemOnly(),
      switchMap(({ gameId }) => this.state$.pipe(map((storeState) => storeState[gameId]))),
      filter((gameConfig) => !!gameConfig && !!gameConfig.configItems),
      map(({ configItems }) => configItems)
    );
  }
}
