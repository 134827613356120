<mat-toolbar color="accent" [ngStyle]='{zIndex: "10"}'>
  <app-back-button></app-back-button>
  <span class="menu-spacer"></span>
  <a
    mat-button
    [routerLink]="[PathsParts.payment]"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: true }"
    #paymentRouterLinkActiveInstance="routerLinkActive"
    [disabled]="paymentRouterLinkActiveInstance.isActive"
    [ngStyle]="paymentRouterLinkActiveInstance.isActive ? { fontWeight: 'lighter' } : {}"
  >
    Credit remaining:
    <span [ngClass]="timeRemainingClass$ | async" [ngStyle]="{ fontWeight: 'lighter' }">
      ${{ ownerBalanceAmount$ | async | currency : '' : '' }}
    </span>
  </a>
  <ng-container *ngIf="(isSmall$ | async) === false">
    <a
      mat-button
      [routerLink]="['/', PathsParts.mygames]"
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      #myGamesRouterLinkActiveInstance="routerLinkActive"
      [disabled]="myGamesRouterLinkActiveInstance.isActive"
      [ngStyle]="myGamesRouterLinkActiveInstance.isActive ? { fontWeight: 'lighter' } : {}"
    >
      My Games
      <mat-icon aria-hidden="true">cloud</mat-icon>
    </a>
    <a
      mat-button
      [routerLink]="[PathsParts.account]"
      routerLinkActive
      [routerLinkActiveOptions]="{ exact: true }"
      #myAccountRouterLinkActiveInstance="routerLinkActive"
      [disabled]="myAccountRouterLinkActiveInstance.isActive"
      [ngStyle]="myAccountRouterLinkActiveInstance.isActive ? { fontWeight: 'lighter' } : {}"
    >
      My Account
      <mat-icon aria-hidden="true">person</mat-icon>
    </a>
    <a mat-button (click)="signOut()">Log Out<mat-icon aria-hidden="true">exit_to_app</mat-icon></a>
  </ng-container>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
    *ngIf="isSmall$ | async"
  >
    <mat-icon aria-hidden="true">more_vert</mat-icon>
  </button>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    [routerLink]="['/', PathsParts.mygames]"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: true }"
    #myGamesMenuRouterLinkActiveInstance="routerLinkActive"
    [disabled]="myGamesMenuRouterLinkActiveInstance.isActive"
  >
    <mat-icon aria-hidden="true">cloud</mat-icon>
    <span>My Games</span>
  </button>
  <button
    mat-menu-item
    [routerLink]="[PathsParts.account]"
    routerLinkActive
    [routerLinkActiveOptions]="{ exact: true }"
    #myAccountMenuRouterLinkActiveInstance="routerLinkActive"
    [disabled]="myAccountMenuRouterLinkActiveInstance.isActive"
  >
    <mat-icon aria-hidden="true">person</mat-icon>
    <span>My Account</span>
  </button>
  <button mat-menu-item (click)="signOut()">
    <mat-icon aria-hidden="true">exit_to_app</mat-icon>
    <span>Log Out</span>
  </button>
</mat-menu>

<mat-card *ngIf="(timeRemainingCategory$ | async) !== 'normal'">
  <mat-card-content>
    <span [ngClass]="timeRemainingClass$ | async" [ngStyle]="{ display: 'flex' }">
      <span [ngStyle]="{ flex: '1 1 auto' }"></span>
      <span class="mat-body-1">
        <ng-container [ngSwitch]="timeRemainingCategory$ | async">
          <ng-container *ngSwitchCase="'low'">
            <mat-icon aria-hidden="false" aria-label="Warning Icon" fontIcon="warning"></mat-icon>
            Estimated time remaining: {{ estimatedCountdown$ | async }}
          </ng-container>
          <ng-container *ngSwitchCase="'none'">
            <mat-icon aria-hidden="false" aria-label="Warning Icon" fontIcon="error"></mat-icon>
            You have no time remaining.
          </ng-container>
        </ng-container>
      </span>
    </span>
  </mat-card-content>
</mat-card>

<router-outlet></router-outlet>
