<table [ngStyle]="{ textAlign: 'center' }">
  <tr>
    <td>Spend this much...</td>
    <td>To get this bonus!</td>
  </tr>
  <tr>
    <td>$10</td>
    <td>$1</td>
  </tr>
  <tr>
    <td>$25</td>
    <td>$5</td>
  </tr>
  <tr>
    <td>$50</td>
    <td>$15</td>
  </tr>
  <tr>
    <td>$100</td>
    <td>$50</td>
  </tr>
</table>
