import { ModelledItem, ModelState, StoredModelledItem } from '@kto/modelled-api-store-service';
import { distinctUntilChanged, filter, map, pipe } from 'rxjs';
import { isRequiredStoredModelledItem } from './identity';

export const filterRequiredStoredModelledItem = () => pipe(filter(isRequiredStoredModelledItem));

export const mapRequiredStoredModelledItem = <T extends ModelledItem>() =>
  pipe(map((storedModelledItem: Required<StoredModelledItem<T>>) => storedModelledItem.item));

export const modelledItemOnly = () => pipe(filterRequiredStoredModelledItem(), mapRequiredStoredModelledItem());

export const listRequiredModelledItem = <T extends ModelledItem>() => pipe(
  map((modelState: ModelState<T>) =>
    Object.values(modelState)
      .filter(isRequiredStoredModelledItem)
      .map(({ item }) => item)
  )
);
export const modelledListIdsUnchanged = <T extends ModelledItem>() =>
  pipe(
    distinctUntilChanged((previous: T[], current: T[]) => {
      const previousKeys = previous.map(({ __id }) => __id);
      const currentKeys = current.map(({ __id }) => __id);
      return previousKeys.length === currentKeys.length && previousKeys.every((itemId) => currentKeys.includes(itemId));
    })
  );
