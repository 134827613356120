import { Component, OnInit } from '@angular/core';
import { CreditDebitService } from '../../services/credit-debit.service';
import { PathParts } from '../../routing/lib';
import { OwnerBalanceService } from '../../services/owner-balance.service';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  protected readonly PathParts = PathParts;
  constructor(private creditDebitService: CreditDebitService, private ownerBalanceService: OwnerBalanceService) {}

  ngOnInit(): void {
    this.creditDebitService.loadCreditsAndDebits();
    this.ownerBalanceService.readOwnerBalance();
  }
}
