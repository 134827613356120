export const naturalSort = (valueA: string | number, valueB: string | number): number =>
  `${valueA}`.localeCompare(`${valueB}`, navigator.languages[0] || navigator.language, { numeric: true });

type SortableObject<T> = { [property in keyof T]: string | number };

export const naturalSortObjectsByProperty = <T extends SortableObject<T>>(
  objectA: T,
  objectB: T,
  property: keyof SortableObject<T>
): number => naturalSort(objectA[property], objectB[property]);

export const naturalSortObjectsByPropertyFn: <T extends SortableObject<T>>(
  property: keyof SortableObject<T>
) => (objectA: T, objectB: T) => number =
  <T extends SortableObject<T>>(property: keyof SortableObject<T>) =>
  (objectA: T, objectB: T) =>
    naturalSortObjectsByProperty(objectA, objectB, property);
