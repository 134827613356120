import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { Credit, CreditType } from '../../models/credit';
import { Debit, DebitType } from '../../models/debit';
import { CreditDebitService } from '../../services/credit-debit.service';
import { isAfter, differenceInMinutes, isBefore, add } from 'date-fns';

export interface DateRangeFormValue {
  start: Date | null;
  end: Date | null;
}

type CreditDebitTableItemType = 'credit' | 'debit';
export interface CreditDebitTableItem {
  itemType: CreditDebitTableItemType;
  transactionType: CreditType | DebitType;
  amount: number;
  startDate: Date;
  endDate?: Date;
  userGameId?: string;
  reason?: string;
}

/**
 * Data source for the CreditDebitTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CreditDebitTableDataSource extends DataSource<CreditDebitTableItem> {
  private groupUsageItems$ = new BehaviorSubject<boolean>(true);
  private dateRangeFilter$ = new BehaviorSubject<Partial<DateRangeFormValue>>({ end: null, start: null });
  data$: Observable<CreditDebitTableItem[]> = this.creditDebitService.state$.pipe(
    map(({ credits, debits }) => ({
      credits: Object.values(credits ?? {}),
      debits: Object.values(debits ?? {}),
    })),
    switchMap(({ credits, debits }) =>
      this.groupUsageItems$.pipe(
        map((groupUsageItems) => [
          ...credits.map(mapCreditDebitTableItem('credit')),
          ...groupDebitUsage(groupUsageItems)(debits.map(mapCreditDebitTableItem('debit'))),
        ]),
        switchMap((tableItems) =>
          this.dateRangeFilter$.pipe(
            map((dateRangeFilter) => tableItems.filter((tableItem) => this.filterPredicate(tableItem, dateRangeFilter)))
          )
        )
      )
    )
  );
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;

  constructor(private creditDebitService: CreditDebitService) {
    super();
  }

  get filter(): Partial<DateRangeFormValue> {
    return this.dateRangeFilter$.value;
  }

  set filter(filterValue: Partial<DateRangeFormValue>) {
    this.dateRangeFilter$.next(filterValue);
  }

  get usageGrouping(): boolean {
    return this.groupUsageItems$.value;
  }

  set usageGrouping(value: boolean) {
    this.groupUsageItems$.next(value);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<CreditDebitTableItem[]> {
    if (this.paginator && this.sort) {
      // Combine everything that affects the rendered data into one update
      // stream for the data-table to consume.
      return merge(this.data$, this.paginator.page, this.sort.sortChange).pipe(
        switchMap(() => this.data$.pipe(map((data) => this.getPagedData(this.getSortedData([...data])))))
      );
    } else {
      throw Error('Please set the paginator and sort on the data source before connecting.');
    }
  }

  private filterPredicate(item: CreditDebitTableItem, filter: Partial<DateRangeFormValue>): boolean {
    if (!item) {
      return false;
    }
    const { start, end } = filter;
    const filterStartDate = start ? new Date(start) : null;
    const filterEndDate = end ? add(new Date(end), { days: 1 }) : null;

    const isAfterStartDate = !filterStartDate || isAfter(item.startDate, filterStartDate);
    const isBeforeEndDate =
      !filterEndDate ||
      (item.endDate ? isBefore(item.endDate, filterEndDate) : isBefore(item.startDate, filterEndDate));

    return isAfterStartDate && isBeforeEndDate;
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {}

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: CreditDebitTableItem[]): CreditDebitTableItem[] {
    if (this.paginator) {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      return data.splice(startIndex, this.paginator.pageSize);
    } else {
      return data;
    }
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: CreditDebitTableItem[]): CreditDebitTableItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort?.direction === 'asc';
      switch (this.sort?.active) {
        case 'date':
          return compare(a.startDate, b.startDate, isAsc);
        case 'amount':
          return compare(a.amount, b.amount, isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
const compare = (a: string | number | Date, b: string | number | Date, isAsc = true): number => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

const mapCreditDebitTableItem =
  (itemType: CreditDebitTableItemType) =>
  (item: Credit | Debit): CreditDebitTableItem => {
    const { type: transactionType, amount, reason, date: dateString } = item;
    const { userGameId } = item as Debit;
    return {
      itemType,
      startDate: new Date(dateString),
      transactionType,
      amount: Number(amount),
      reason,
      ...(userGameId ? { userGameId } : {}),
    };
  };

const MINUTES_GROUPING_THRESHOLD = 5;

const groupDebitUsage =
  (groupFlag: boolean) =>
  (debits: CreditDebitTableItem[]): CreditDebitTableItem[] => {
    if (!groupFlag) {
      return debits;
    }

    const usageItemsByUserGame: { [userGameId: string]: CreditDebitTableItem[] } = {};
    const nonUsageItems: CreditDebitTableItem[] = [];
    const groupedUsageItems: CreditDebitTableItem[] = [];

    debits
      .sort(({ startDate: a }, { startDate: b }) => compare(a, b))
      .forEach((item) => {
        if (item.transactionType !== DebitType.Usage || !item.userGameId) {
          nonUsageItems.push(item);
        } else {
          if (!usageItemsByUserGame[item.userGameId]) {
            usageItemsByUserGame[item.userGameId] = [];
          }
          usageItemsByUserGame[item.userGameId].push(item);
        }
      });

    Object.values(usageItemsByUserGame).forEach((userGameUsageItems) => {
      let groupedUsageItem: CreditDebitTableItem;
      userGameUsageItems.forEach((currentItem, index, array) => {
        if (index === 0) {
          return;
        }

        const previousItem = array[index - 1];
        const currentGroupedItem = groupedUsageItem ?? { ...previousItem };

        if (
          differenceInMinutes(currentItem.startDate, previousItem.startDate, { roundingMethod: 'floor' }) <
          MINUTES_GROUPING_THRESHOLD
        ) {
          groupedUsageItem = {
            ...currentGroupedItem,
            endDate: currentItem.startDate,
            amount: currentGroupedItem.amount + currentItem.amount,
          };
          if (index === array.length - 1) {
            groupedUsageItems.push(groupedUsageItem);
            return;
          }
        } else {
          groupedUsageItems.push(groupedUsageItem);
          groupedUsageItem = currentItem;
        }
      });
    });

    return [...nonUsageItems, ...groupedUsageItems];
  };
