import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableProdMode } from "@angular/core";
import { Amplify } from "aws-amplify";
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// https://docs.amplify.aws/lib/auth/start/q/platform/js/#re-use-existing-authentication-resource
Amplify.configure({
  Auth: environment.cognito,
  Storage: {
    ...environment.storage,
    customPrefix: {
      public: 'public/',
      protected: '',
      private: '',
    },
    level: 'private',
  },
});
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
