<!--suppress JSUnresolvedReference, JSUnusedGlobalSymbols -->
<!--MatTable doesn't support typing for row items, so it's easier to just suppress all unresolved references -->
<div [ngStyle]="{ display: 'flex', flexFlow: 'wrap', marginTop: '1em' }">
  <mat-form-field [ngStyle]="{ marginRight: '1em', flex: '0 0 auto' }">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="dateRangeForm" [rangePicker]="picker">
      <input matStartDate [formControl]="dateRangeForm.controls.start" placeholder="Start date" />
      <input matEndDate [formControl]="dateRangeForm.controls.end" placeholder="End date" />
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>

    <mat-error *ngIf="dateRangeForm.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="dateRangeForm.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

  <mat-checkbox [formControl]="usageGroupingToggle">
    <mat-label
      [ngStyle]="{
        fontFamily: 'var(--mat-form-field-subscript-text-font)',
        fontWeight: 'var(--mat-form-field-subscript-text-weight)',
        fontSize: 'var(--mat-form-field-subscript-text-size)',
        color: 'var(--text-primary-color)'
      }"
      >Group Usage Details</mat-label
    >
  </mat-checkbox>

  <span [ngStyle]="{ flex: '1 1 auto' }"></span>

  <app-throttled-button (release)="refreshData()">
    <div [ngStyle]="{ display: 'flex', alignItems: 'center' }">
      <mat-icon aria-hidden="true" [ngStyle]="{ marginRight: '0.3em' }">refresh</mat-icon>
      <span>Refresh</span>
    </div>
  </app-throttled-button>
</div>
<div class="mat-elevation-z8">
  <table
    mat-table
    class="full-width-table"
    matSort
    [matSortActive]="'date'"
    [matSortDirection]="'asc'"
    aria-label="Elements"
  >
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true">Date</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row">
          <span [ngStyle]="{ display: 'flex', alignItems: 'center' }">
            <span>
              {{ row.startDate | date : 'short' }}
            </span>
            <span *ngIf="row.endDate"> - </span>
            <span *ngIf="row.endDate">{{ row.endDate | date : 'short' }}</span>
          </span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row">
          {{ row.amount | currency : 'CAD' : 'symbol-narrow' : '1.2-5' }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="reason">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row">
          <ng-container *ngIf="row.userGameId">
            <span *ngIf="row.userGameId | userGameIdToUserGameName$ | async as userGameName; else userGameNotAvailable">
              Game Server: {{ userGameName }}</span
            >
            <ng-template #userGameNotAvailable>
              <span> Game Server details no longer available </span>
            </ng-template>
          </ng-container>
          <span *ngIf="row.userGameId && row.reason"> - </span>
          <span *ngIf="row.reason"> {{ row.reason }}</span>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    *ngIf="dataSource.data$ | async as data"
    [length]="data.length"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 20, 50, 100, 500]"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
