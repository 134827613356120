import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HumanReadableRegionName, REGION_CONFIG_NAME_MAP, RegionConfigName } from '../../models/region-config';
import { AppApiService } from '../../services/app-api.service';
import { Observable, map } from 'rxjs';
import { listRequiredModelledItem, modelledListIdsUnchanged } from '../../util/rxjs-pipes';
import { naturalSortObjectsByPropertyFn } from '../../util/sort';

@Component({
  selector: 'app-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss'],
})
export class RegionSelectorComponent implements OnInit {
  @Input() parentControl: FormControl<RegionConfigName | null>;
  regionOptions$: Observable<{ label: HumanReadableRegionName; value: RegionConfigName }[]>;

  constructor(private appApiService: AppApiService) {}

  ngOnInit(): void {
    this.regionOptions$ = this.appApiService.regionConfigs$.pipe(
      listRequiredModelledItem(),
      modelledListIdsUnchanged(),
      map((regionConfigs) => regionConfigs.filter(({ active }) => active).map(({ name }) => name)),
      map((regionConfigNames) => regionConfigNames.map((value) => ({ label: REGION_CONFIG_NAME_MAP[value], value }))),
      map((regionOptions) => regionOptions.sort(naturalSortObjectsByPropertyFn('label')))
    );
  }
}
