import { Component, OnDestroy } from '@angular/core';
import { Auth } from 'aws-amplify';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserService } from '../../services/user.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

interface PasswordForm {
  oldPassword: FormControl<string | null>;
  newPassword: FormControl<string | null>;
  confirmNewPassword: FormControl<string | null>;
}

@Component({
  selector: 'app-amplify-password-form',
  templateUrl: './amplify-password-form.component.html',
  styleUrls: ['./amplify-password-form.component.scss'],
})
export class AmplifyPasswordFormComponent implements OnDestroy {
  passwordForm: FormGroup<PasswordForm>;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmNewPassword = true;

  updateError = false;

  username$: Observable<string | undefined>;
  private ngUnsubscribe = new Subject<void>();

  constructor(private fb: FormBuilder, private userService: UserService, private snackBar: MatSnackBar) {
    this.passwordForm = this.fb.group<PasswordForm>({
      oldPassword: new FormControl<string>('', [Validators.required]),
      newPassword: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\^,$*.[\]{}()?\-"!@#%&/\\><':;|_~`+=]).*$/),
      ]),
      confirmNewPassword: new FormControl<string>('', {
        validators: [
          Validators.required,
          (control): null | { passwordMismatch: boolean } => {
            if (control.parent) {
              const formGroup = control.parent as FormGroup<PasswordForm>;
              if (formGroup.controls.newPassword.value !== formGroup.controls.confirmNewPassword.value) {
                return { passwordMismatch: true };
              }
            }
            return null;
          },
        ],
      }),
    });

    this.passwordForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => (this.updateError = false));

    this.passwordForm.controls.newPassword.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.passwordForm.controls.confirmNewPassword.updateValueAndValidity());

    this.username$ = this.userService.userState$.pipe(map((userState) => userState.user?.username));
  }

  async onSubmit(formDirective: FormGroupDirective): Promise<void> {
    if (
      this.passwordForm.controls.oldPassword.value &&
      this.passwordForm.controls.newPassword.value &&
      this.passwordForm.controls.confirmNewPassword.value
    ) {
      this.passwordForm.disable();
      try {
        await changePassword(
          this.passwordForm.controls.oldPassword.value,
          this.passwordForm.controls.newPassword.value,
          this.passwordForm.controls.confirmNewPassword.value
        );
        formDirective.resetForm();
        this.passwordForm.reset();
        this.snackBar.open('Password Updated', 'OK');
      } catch (e: any) {
        console.error(e);
        this.snackBar.open(e.message || 'Unable to Update Password - Please Try Again', 'OK');
      } finally {
        this.passwordForm.enable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

const changePassword = async (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
  if (newPassword !== confirmNewPassword) {
    throw new Error("Passwords don't match!");
  }
  const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  await Auth.changePassword(user, oldPassword, newPassword);
};
