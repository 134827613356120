<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Add new game</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-card-content dashboard-card-overflow">
    <form *ngIf="newUserGameForm" [formGroup]="newUserGameForm">
      <mat-form-field appearance="outline" *ngIf="sortedGamesList$ | async as games" style="margin-top: 5px">
        <mat-label>Game</mat-label>
        <mat-select [formControl]="newUserGameForm.controls.gameId">
          <mat-option *ngFor="let game of games" [value]="game.__id">{{ game.name }}</mat-option>
        </mat-select>
        <div *ngIf="selectedGameId">
          <ng-container *ngFor="let game of games">
            <div appGameLogo [gameOrUserGame]="game" *ngIf="selectedGameId === game.__id"></div>
          </ng-container>
        </div>
      </mat-form-field>
      <div class="mat-toolbar-row">
        <h2>Game Name</h2>
      </div>
      <mat-form-field appearance="outline">
        <input matInput [formControl]="newUserGameForm.controls.name" placeholder="Game Name" />
      </mat-form-field>
      <div class="mat-toolbar-row">
        <h2>Choose your Server Type</h2>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Server Type</mat-label>
        <mat-select [formControl]="newUserGameForm.controls.class">
          <mat-option *ngFor="let userGameClass of classOptions" [value]="userGameClass">{{
            userGameClass
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mat-toolbar-row">
        <h2>Choose your Region</h2>
      </div>
      <app-region-selector [parentControl]="newUserGameForm.controls.instanceRegion"></app-region-selector>

      <div class="mat-toolbar-row">
        <button
          mat-raised-button
          color="primary"
          (click)="onSubmitNewUserGame()"
          [disabled]="!newUserGameForm.valid || newUserGameForm.disabled"
        >
          Save
        </button>
        <button mat-raised-button color="warn" (click)="onCancelNewUserGame()" [disabled]="newUserGameForm.disabled">
          Cancel
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
