import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { GameConfigItem, GameConfig } from '../../interfaces/game-config-item';
import { s3PutGameConfig } from '../../util/s3';

@Component({
  selector: 'app-game-config-item-form',
  templateUrl: 'game-config-item-form.component.html',
})
export class GameConfigItemFormComponent implements OnChanges {
  @Input() configItems: GameConfigItem[];
  @Input() userGameId: string;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() formDisabled = new EventEmitter<boolean>();

  formGroup: FormGroup;
  state$: Observable<any>;

  disableForm(disabled: boolean) {
    disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.formDisabled.emit(disabled);
  }

  constructor(private fb: FormBuilder) {}
  ngOnChanges(_changes: SimpleChanges): void {
    this.formGroup = this.fb.group({});

    this.configItems.forEach((configItem) => {
      const initialValue = configItem.value || configItem.defaultValue;
      let control: FormControl | undefined = undefined;
      switch (configItem.inputType) {
        case 'text':
          control = this.fb.control(initialValue ? `${initialValue}` : '');
          break;
        case 'number':
          control = this.fb.control(initialValue);
          break;
        case 'checkbox':
          control = this.fb.control(!!initialValue);
      }
      if (control) {
        this.formGroup.addControl(configItem.name, control);
      } else {
        console.error('Encountered invalid config item', configItem);
      }
    });

    this.state$ = this.formGroup.valueChanges;
  }

  async onCancelGameConfig(): Promise<void> {
    // Emit the event to close the dialog
    this.closeDialog.emit();
  }

  async onSubmitGameConfig(): Promise<void> {
    // Upload user's game config to S3
    await this.putGameConfig(this.userGameId, this.formGroup.value);
  }

  private async putGameConfig(userGameId: string, gameConfig: GameConfig): Promise<void> {
    // Upload gameConfiguration's JSON schema to S3
    // Get gameConfigurationId, get gameId
    this.disableForm(true);
    try {
      await s3PutGameConfig(userGameId, gameConfig);
    } catch (error: unknown) {
      console.error(error);
    } finally {
      this.disableForm(false);
    }
  }
}
