<app-user-game-delete-wrapper [userGame]="userGame" #deleteWrapperComponent>
  <mat-card [ngClass]="userGameStateClass">
    <mat-card-header>
      <div mat-card-avatar appGameLogo [gameOrUserGame]="userGame"></div>
      <mat-card-title [ngStyle]="{ hyphens: 'auto' }">
        {{ userGame.name }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <br />
      <p class="mat-body-1">
        Status:
        <ng-container [ngSwitch]="serverState">
          <span *ngSwitchCase="'serverIsStopping'"> Game Server is stopping... </span>
          <span *ngSwitchCase="'serverIsRestarting'"> Game Server is restarting... </span>
          <span *ngSwitchCase="'serverIsStarting'"> Game Server is starting... </span>
          <span *ngSwitchCase="'serverIsRunning'"> Game Server is running! </span>
          <span *ngSwitchCase="'instanceIsOffline'"> Offline </span>
          <span *ngSwitchCase="'unknown'" class="mat-warn"> Not sure, but we're checking... </span>
          <span *ngSwitchDefault> Not sure, but we're checking... </span>
        </ng-container>
      </p>
      <p class="mat-body-1">Region: {{ humanReadableRegion }}</p>
      <p class="mat-body-1" *ngIf="instanceIsRunning && userGame.publicIpAddress">
        IP Address: {{ userGame.publicIpAddress }}
      </p>
      <p class="mat-body-1">Server Type: {{ userGame.class }}</p>
      <span [ngStyle]="{ display: 'flex', flexFlow: 'wrap' }">
        <button
          mat-raised-button
          color="accent"
          *ngIf="instanceIsRunning"
          (click)="openServerDetails()"
          class="card-button"
        >
          View Server Details
        </button>
        <button mat-raised-button color="accent" (click)="openGameConfig()" class="card-button">
          Edit Game Configuration
        </button>
      </span>
    </mat-card-content>
    <mat-card-actions align="end">
      <app-user-game-countdown [userGame$]="userGame | userGameToUserGame$"></app-user-game-countdown>
      <app-user-game-controls
        [userGame]="userGame"
        [showDelete]="true"
        [disableDelete]="!instanceIsOffline"
        (deleteClicked)="deleteWrapperComponent.deleteGameServer()"
      ></app-user-game-controls>
    </mat-card-actions>
  </mat-card>
</app-user-game-delete-wrapper>
