import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserGameService } from '../../services/user-game.service';
import {
  GameDeleteDialogComponent,
  GameDeleteDialogInput,
} from '../../dialogs/game-delete-dialog/game-delete-dialog.component';
import { UserGame } from '../../models/user-game';
import { Router } from '@angular/router';
import { PathParts } from '../../routing/lib';

@Component({
  selector: 'app-user-game-delete-wrapper',
  templateUrl: './user-game-delete-wrapper.component.html',
  styleUrls: ['./user-game-delete-wrapper.component.scss'],
})
export class UserGameDeleteWrapperComponent {
  @Input() userGame: UserGame;
  constructor(public dialog: MatDialog, private userGameService: UserGameService, private router: Router) {}

  deleteGameServer() {
    const dialogConfig: GameDeleteDialogInput = {
      userGame: this.userGame,
    };

    this.dialog
      .open<GameDeleteDialogComponent, GameDeleteDialogInput, boolean>(GameDeleteDialogComponent, {
        data: dialogConfig,
        disableClose: true,
        panelClass: 'themed-overlay-color',
      })
      .afterClosed()
      .subscribe(async (deleteConfirmed) => {
        if (deleteConfirmed) {
          this.userGameService.deleteUserGame(this.userGame.__id);
          await this.router.navigate([PathParts.mygames]);
        }
      });
  }
}
