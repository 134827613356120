import { Component, Inject, OnDestroy } from '@angular/core';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { StoredModelledItem, StoredStatus } from '@kto/modelled-api-store-service';
import { Payment } from '../../models/payment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getCcStatementDescriptor } from '../../lib/billing';
import { OwnerBalanceService } from '../../services/owner-balance.service';

export interface ProcessingPaymentDialogInput {
  createPayment$: Observable<StoredModelledItem<Payment>>;
}

@Component({
  selector: 'app-processing-payment-dialog',
  templateUrl: './processing-payment-dialog.component.html',
  styleUrls: ['./processing-payment-dialog.component.scss'],
})
export class ProcessingPaymentDialogComponent implements OnDestroy {
  createPayment$: Observable<StoredModelledItem<Payment>>;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProcessingPaymentDialogInput,
    private ownerBalanceService: OwnerBalanceService
  ) {
    this.createPayment$ = this.data.createPayment$;
    this.createPayment$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((storedPayment) => storedPayment.state.status === StoredStatus.UPDATED),
        take(1)
      )
      .subscribe(() => this.ownerBalanceService.readOwnerBalance());
  }

  protected readonly StoredStatus = StoredStatus;
  protected readonly getCcStatementDescriptor = getCcStatementDescriptor;

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
