import { Storage } from 'aws-amplify';

export const s3PutGameConfig = async (userGameId: string, gameConfig: any): Promise<void> => {
  const url = `userGames/${userGameId}/gameConfig.json`;

  await Storage.vault.put(url, gameConfig, {
    level: 'private',
    contentType: 'application/json',
  });
}
