import { ValhallaModel } from '../models/lib';

export const OWNER_BALANCE_ID_SUFFIX = `_${ValhallaModel.OwnerBalance}`;

export const USER_CREDIT_WARNING_MINUTES = 30;

export type TimeRemainingCategory = 'low' | 'none' | 'normal';

export const getTimeRemainingCategory = (minutesRemaining: number | undefined): TimeRemainingCategory => {
  if (!minutesRemaining || minutesRemaining <= 0) {
    return 'none';
  } else if (minutesRemaining <= USER_CREDIT_WARNING_MINUTES) {
    return 'low';
  } else {
    return 'normal';
  }
};
