import { Injectable } from '@angular/core';
import { AppApiService } from './app-api.service';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  Observable,
  of,
  sample,
  scan,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { UserGame, UserGameState } from '../models/user-game';
import { StoredStatus } from '@kto/modelled-api-store-service';
import { UserGameServerEventPayload } from '../interfaces/game-events';
import { AppEventApiService, EventTypes } from './app-event-api.service';
import { ACTIVE_POLLING_INTERVAL_MS, PASSIVE_POLLING_INTERVAL_MS } from '../lib/polling';
import { OwnerBalanceService } from './owner-balance.service';
import { UserService } from './user.service';
import { listRequiredModelledItem, modelledItemOnly } from '../util/rxjs-pipes';

@Injectable({
  providedIn: 'root',
})
export class UserGameService {
  private userGamePollers: {
    [userGameId: string]: {
      pollingSubject: Subject<boolean>;
      ngUnsubscribe: Subject<void>;
    };
  } = {};

  get userGames$(): Observable<UserGame[]> {
    return this.userService.ownerId$.pipe(
      switchMap((ownerId) => {
        if (!ownerId) {
          return of([]);
        }

        return this.appApiService.userGamesByOwnerId$(ownerId).pipe(listRequiredModelledItem());
      })
    );
  }

  constructor(
    private appApiService: AppApiService,
    private appEventApiService: AppEventApiService,
    private ownerBalanceService: OwnerBalanceService,
    private userService: UserService
  ) {
    this.userGames$
      .pipe(
        distinctUntilChanged((previous, current) => {
          const previousKeys = previous.map(({ __id }) => __id);
          const currentKeys = current.map(({ __id }) => __id);
          return (
            previousKeys.length === currentKeys.length && previousKeys.every((itemId) => currentKeys.includes(itemId))
          );
        })
      )
      .subscribe((userGames) => {
        userGames.forEach((userGame) => {
          const userGameId = userGame.__id;
          if (!this.userGamePollers[userGameId]) {
            this.userGamePollers[userGameId] = {
              pollingSubject: new BehaviorSubject<boolean>(false),
              ngUnsubscribe: new Subject(),
            };
            const userGamePoller = this.userGamePollers[userGameId];

            const sampler$ = userGamePoller.pollingSubject.asObservable().pipe(
              distinctUntilChanged(),
              switchMap((activePollingFlag) => {
                return activePollingFlag ? timer(0, ACTIVE_POLLING_INTERVAL_MS) : timer(0, PASSIVE_POLLING_INTERVAL_MS);
              })
            );

            this.getUserGame$(userGameId)
              .pipe(
                takeUntil(userGamePoller.ngUnsubscribe),
                map((userGame) => userGame.state || UserGameState.UNKNOWN),
                distinctUntilChanged()
              )
              .subscribe((userGameState) => {
                const activelyPoll = UserGameState.INSTANCE_OFFLINE < userGameState;
                this.activelyPollUserGame(userGame, activelyPoll);
              });

            this.appApiService
              .userGame$(userGameId)
              .pipe(
                takeUntil(userGamePoller.ngUnsubscribe),
                sample(sampler$),
                filter((storedUserGame) => storedUserGame.state.status !== StoredStatus.PENDING),
                tap((storedUserGame) => {
                  if (storedUserGame.state.status === StoredStatus.ERROR) {
                    throw storedUserGame.state.error ?? new Error('Error polling UserGame');
                  }
                })
              )
              .subscribe({
                next: (_storedUserGame) => {
                  this.appApiService.readUserGame$(userGameId);
                },
                error: (err) => {
                  console.error(err);
                  // TODO: Redirect? Popup?
                },
              });
          }
        });

        const storedUserGameIds = userGames.map(({ __id }) => __id);

        Object.keys(this.userGamePollers)
          .filter(
            (pollerUserGameId) => !storedUserGameIds.some((storedUserGameId) => storedUserGameId === pollerUserGameId)
          )
          .forEach((removedUserGameId) => {
            this.userGamePollers[removedUserGameId].ngUnsubscribe.next();
            this.userGamePollers[removedUserGameId].ngUnsubscribe.complete();
            delete this.userGamePollers[removedUserGameId];
          });
      });
  }

  private activelyPollUserGame(userGame: UserGame, poll: boolean): void {
    this.userGamePollers[userGame.__id].pollingSubject.next(poll);
  }

  async startUserGame(userGameId: string): Promise<void> {
    this.ownerBalanceService.readOwnerBalance();
    const eventData: UserGameServerEventPayload = {
      userGameId,
    };
    this.appApiService.setUserGameState(userGameId, UserGameState.USER_REQUESTED_START);
    try {
      await lastValueFrom(this.appEventApiService.sendEvent$(EventTypes.CREATE, eventData));
    } catch (e) {
      this.appApiService.setUserGameState(userGameId, UserGameState.ERROR_ON_USER_REQUEST);
      throw e;
    }
  }
  async stopUserGame(userGameId: string): Promise<void> {
    const eventData: UserGameServerEventPayload = {
      userGameId,
    };
    this.appApiService.setUserGameState(userGameId, UserGameState.USER_REQUESTED_TERMINATION);
    try {
      await lastValueFrom(this.appEventApiService.sendEvent$(EventTypes.DELETE, eventData));
    } catch (e) {
      this.appApiService.setUserGameState(userGameId, UserGameState.ERROR_ON_USER_REQUEST);
      throw e;
    } finally {
      this.ownerBalanceService.readOwnerBalance();
    }
  }
  async restartUserGame(userGameId: string): Promise<void> {
    const eventData: UserGameServerEventPayload = {
      userGameId,
    };
    this.appApiService.setUserGameState(userGameId, UserGameState.USER_REQUESTED_RESTART);
    try {
      await lastValueFrom(this.appEventApiService.sendEvent$(EventTypes.RESTART_GAME_SERVER, eventData));
    } catch (e) {
      this.appApiService.setUserGameState(userGameId, UserGameState.ERROR_ON_USER_REQUEST);
      throw e;
    } finally {
      this.ownerBalanceService.readOwnerBalance();
    }
  }

  updateUserGame(userGameId: string, userGame: Partial<UserGame>): void {
    this.appApiService.updateUserGame$({
      userGame,
      userGameId,
    });
  }

  deleteUserGame(userGameId: string): void {
    this.appApiService.setUserGameState(userGameId, UserGameState.DELETING);
    this.appApiService.deleteUserGame$(userGameId);
  }

  userGameIsStored(userGameId: string): boolean {
    const storedUserGame = this.appApiService.userGamesState
      ? this.appApiService.userGamesState[userGameId]
      : undefined;
    if (!storedUserGame) {
      return false;
    }

    return !!storedUserGame.item || storedUserGame.state.status === StoredStatus.PENDING;
  }

  getStoredUserGameStatus(userGameId: string): Observable<StoredStatus> {
    return this.appApiService.userGame$(userGameId).pipe(map((storedUserGame) => storedUserGame.state.status));
  }

  getUserGame$(userGameId: string): Observable<UserGame> {
    return this.appApiService.userGame$(userGameId).pipe(
      modelledItemOnly(),
      scan((previousUserGame, currentUserGame, index) => {
        if (index === 0) {
          return currentUserGame;
        }
        const updatedUserGame = { ...currentUserGame };
        const previousState = previousUserGame.state ?? UserGameState.UNKNOWN;
        const currentState = currentUserGame.state ?? UserGameState.UNKNOWN;

        let calculatedState: UserGameState;

        if (
          previousState === UserGameState.GAME_SERVER_RUNNING ||
          currentState === UserGameState.DELETING ||
          (currentState === UserGameState.INSTANCE_OFFLINE && previousState >= UserGameState.USER_REQUESTED_TERMINATION)
        ) {
          calculatedState = currentState;
        } else if (
          previousState >= UserGameState.USER_REQUESTED_RESTART &&
          previousState < UserGameState.USER_REQUESTED_TERMINATION
        ) {
          // There should always be a delay after a restart is requested and the server going offline,
          // so we should expect several 'running' states before the server actually shuts down
          calculatedState = currentState < UserGameState.GAME_SERVER_RUNNING ? currentState : previousState;
        } else {
          calculatedState = currentState >= previousState ? currentState : previousState;
        }
        updatedUserGame.state = calculatedState;
        return updatedUserGame;
      })
    );
  }
}
