<mat-card [ngStyle]="{ margin: '2em' }">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary" aria-hidden="true">info</mat-icon>
      Terms of Service
    </mat-card-title>
    <mat-card-subtitle> Acceptance of the Terms of Service is required to continue </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <br />
    <a mat-flat-button color="accent" href="https://terms.valhalla.guru/" rel="noopener noreferrer" target="_blank">
      ValhallaGuru Terms of Service
    </a>
    <br />
    <br />
    <mat-checkbox [(ngModel)]="termsAccepted">
      <mat-label
        [ngStyle]="{
          fontFamily: 'var(--mat-form-field-subscript-text-font)',
          fontWeight: 'var(--mat-form-field-subscript-text-weight)',
          fontSize: 'var(--mat-form-field-subscript-text-size)',
          color: 'var(--text-primary-color)'
        }"
      >
        I have read and accepted the ValhallaGuru Terms of Service
      </mat-label>
    </mat-checkbox>
    <br />
    <br />
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" class="tos-button" [mat-dialog-close]="true" [disabled]="!termsAccepted">
      Submit
    </button>
    <button mat-flat-button color="warn" class="tos-button" [mat-dialog-close]="false">Exit</button>
  </mat-card-actions>
</mat-card>
