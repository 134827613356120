import { Component, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { PathParts } from '../../routing/lib';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OwnerBalanceService } from '../../services/owner-balance.service';
import { getTimeRemainingCategory, TimeRemainingCategory } from '../../lib/owner-balance';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.scss'],
})
export class UserNavComponent {
  ownerBalanceAmount$: Observable<number> = this.ownerBalanceService.ownerBalanceAmount$;

  estimatedMinutesRemaining$ = this.ownerBalanceService.estimatedMinutesRemaining$;
  estimatedCountdown$ = this.ownerBalanceService.estimatedCountdown$;

  timeRemainingCategory$: Observable<TimeRemainingCategory> = this.estimatedMinutesRemaining$.pipe(
    map(getTimeRemainingCategory)
  );

  timeRemainingClass$: Observable<{ [klass: string]: any }> = this.timeRemainingCategory$.pipe(
    map((timeRemainingCategory) => {
      return {
        'low-amount-color': timeRemainingCategory === 'low',
        'zero-amount-color': timeRemainingCategory === 'none',
      };
    })
  );

  private breakpointObserver = inject(BreakpointObserver);

  isSmall$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private router: Router,
    private userService: UserService,
    private ownerBalanceService: OwnerBalanceService
  ) {}

  signOut = async (): Promise<void> => {
    await this.userService.signOut();
  };
  protected readonly PathsParts = PathParts;
}
