import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';

const RATIO = 0.226666666666667;
type StripeBrandColor = 'white' | 'black' | 'blurple';
type Alignment = 'left' | 'right' | 'center';

interface PoweredByStripeSpanComponentChanges extends SimpleChanges {
  brandColor: SimpleChange;
  width: SimpleChange;
  align: SimpleChange;
}

@Component({
  selector: 'app-powered-by-stripe-span',
  templateUrl: './powered-by-stripe-span.component.html',
  styleUrls: ['./powered-by-stripe-span.component.scss'],
})
export class PoweredByStripeSpanComponent implements OnInit, OnChanges {
  @Input() brandColor: StripeBrandColor = 'blurple';
  @Input() width = 150;
  @Input() align: Alignment = 'right';

  private staticWidth: number;
  get initializationWidth(): number {
    return this.staticWidth;
  }

  get height(): number {
    return this.staticWidth * RATIO;
  }

  ngOnInit(): void {
    this.staticWidth = this.width;
  }

  ngOnChanges(changes: PoweredByStripeSpanComponentChanges): void {
    if (changes.width && !changes.width.firstChange) {
      console.warn(
        'Powered By Stripe badge width was updated dynamically after component initialization ' +
          '- change will be ignored'
      );
    }
  }
}
