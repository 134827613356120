import { Auth } from 'aws-amplify';
import { environment } from '../../environments/environment';
import { TEST_USER_ID } from '../services/app-api.service';

export const getCurrentCognitoSub = async (): Promise<string> => {
  try {
    return (await Auth.currentSession()).getAccessToken().payload['sub'];
  } catch (_) {
    if (environment.fixtures.enable) {
      return TEST_USER_ID;
    } else {
      return '';
    }
  }
};

export const getCurrentCognitoFederatedSub = async (): Promise<string> => {
  return (await Auth.currentCredentials()).identityId.split(':')[1];
};
