import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { RegionConfigs$ } from '../lib';
import { inject } from '@angular/core';
import { AppApiService } from '../../services/app-api.service';
import { of } from 'rxjs';

export const regionConfigs$Resolver: ResolveFn<RegionConfigs$> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
) => {
  const appApiService = inject(AppApiService);

  if (!appApiService.regionConfigsState) {
    appApiService.listRegionConfigs$();
  }

  return of(appApiService.regionConfigs$);
};
