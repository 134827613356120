import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Games$ } from '../lib';
import { inject } from '@angular/core';
import { AppApiService } from '../../services/app-api.service';
import { of } from 'rxjs';

export const games$Resolver: ResolveFn<Games$> = (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
  const appApiService = inject(AppApiService);

  if (!appApiService.gamesState) {
    appApiService.listGames$();
  }

  return of(appApiService.games$);
};
