import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-acceptance-dialog',
  templateUrl: './terms-acceptance-dialog.component.html',
  styleUrls: ['./terms-acceptance-dialog.component.scss'],
})
export class TermsAcceptanceDialogComponent {
  public termsAccepted = false;


}
