import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CreditDebitTableDataSource, CreditDebitTableItem, DateRangeFormValue } from './credit-debit-table-datasource';
import { CreditDebitService } from '../../services/credit-debit.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';
import { TypedModelledFormGroup } from '../../util/forms';

@Component({
  selector: 'app-credit-debit-table',
  templateUrl: './credit-debit-table.component.html',
  styleUrls: ['./credit-debit-table.component.scss'],
})
export class CreditDebitTableComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<CreditDebitTableItem>;
  dataSource = new CreditDebitTableDataSource(this.creditDebitService);
  dateRangeForm: TypedModelledFormGroup<DateRangeFormValue, 'start' | 'end'>;
  usageGroupingToggle = new FormControl<boolean>(this.dataSource.usageGrouping);

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['date', 'amount', 'reason'];

  private ngUnsubscribe = new Subject<void>();

  constructor(private creditDebitService: CreditDebitService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.dateRangeForm = this.fb.group({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });
    this.dateRangeForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dateRange) => (this.dataSource.filter = dateRange));
    this.usageGroupingToggle.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((groupUsage) => (this.dataSource.usageGrouping = groupUsage ?? true));
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ direction }) => {
      if (!direction) {
        this.sort.sort({
          disableClear: false,
          id: 'date',
          start: 'asc',
        });
      }
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  refreshData(): void {
    this.creditDebitService.loadCreditsAndDebits();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
