/**
 * Provides an Enum between defined Models and their corresponding API resource names
 */
export enum ValhallaModel {
  Owner = 'Owner',
  Game = 'Game',
  UserGame = 'UserGame',
  Payment = 'Payment',
  Credit = 'Credit',
  Debit = 'Debit',
  OwnerBalance = 'OwnerBalance',
  RegionConfig = 'RegionConfig',
  UserProfile = 'UserProfile'
}
