import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, NEVER, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserGame } from '../../models/user-game';
import { UserGameService } from '../../services/user-game.service';
import { trackUserGame } from '../../util/track-by';
import { naturalSortObjectsByPropertyFn } from '../../util/sort';
import { NavigationEnd, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { PathParts } from 'src/app/routing/lib';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) matSidenav: MatSidenav;
  userGames$: Observable<UserGame[]>;
  isSmall$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  isAuthenticated$: Observable<boolean> = this.userService.isAuthenticated$;
  protected readonly RootPaths = PathParts;
  protected readonly trackUserGame = trackUserGame;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private userGameService: UserGameService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService
  ) {
    this.userGames$ = this.userGameService.userGames$.pipe(
      map((userGames) => userGames.sort(naturalSortObjectsByPropertyFn('name')))
    );
  }

  ngOnInit(): void {
    this.isSmall$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap((isSmall) => (isSmall ? this.router.events : NEVER)),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe(async () => await this.matSidenav.close());
  }

  async signOut(): Promise<void> {
    await this.userService.signOut();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  protected readonly PathParts = PathParts;
}
