export enum InstanceType {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  XLARGE = 'Xlarge'
}

export enum GameShortName {
  VALHEIM = 'valheim',
  EMPYRION = 'empyrion',
  TF2 = 'tf2',
  '7D2D' = '7d2d',
  SATISFACTORY = 'satisfactory',
  TERRARIA = 'terraria',
  COREKEEPER = 'corekeeper',
  ENSHROUDED = 'enshrouded',
}

export enum PaymentProvider {
  Stripe = 'Stripe'
}
