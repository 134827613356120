import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserGame, UserGameState } from '../../models/user-game';
import { OwnerBalanceService } from '../../services/owner-balance.service';
import { distinctUntilChanged, Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-game-countdown',
  templateUrl: './user-game-countdown.component.html',
  styleUrls: ['./user-game-countdown.component.scss'],
})
export class UserGameCountdownComponent implements OnChanges {
  @Input() userGame$: Observable<UserGame>;
  userGameCountdown$: Observable<string>;
  userGameIsRunning$: Observable<boolean>;

  countDownClass$: Observable<{ [klass: string]: any }>;

  constructor(private ownerBalanceServer: OwnerBalanceService) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.userGameIsRunning$ = this.userGame$.pipe(
      map(({ state }) => !!state && state > UserGameState.INSTANCE_OFFLINE)
    );

    this.userGameCountdown$ = this.userGame$.pipe(
      map(({ __id: userGameId }) => userGameId),
      distinctUntilChanged(),
      switchMap((userGameId) => this.ownerBalanceServer.getUserGameCountdown$(userGameId))
    );

    this.countDownClass$ = this.userGameIsRunning$.pipe(
      map((userGameIsRunning) => ({
        'not-active': !userGameIsRunning,
        active: userGameIsRunning,
      }))
    );
  }
}
