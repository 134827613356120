<div [ngStyle]="{ display: 'flex', flexFlow: 'wrap' }">
  <form [ngStyle]="{ marginRight: '1em' }" [formGroup]="passwordForm" #formDirective="ngForm">
    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Enter your old password</mat-label>
      <input
        matInput
        [formControl]="passwordForm.controls.oldPassword"
        [type]="hideOldPassword ? 'password' : 'text'"
        autocomplete="current-password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideOldPassword = !hideOldPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideOldPassword"
      >
        <mat-icon>{{ hideOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="!passwordForm.controls.oldPassword.valid">Must not be blank</mat-error>
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Enter your new password</mat-label>
      <input
        matInput
        [formControl]="passwordForm.controls.newPassword"
        [type]="hideNewPassword ? 'password' : 'text'"
        autocomplete="new-password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideNewPassword = !hideNewPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideNewPassword"
      >
        <mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error *ngIf="!passwordForm.controls.newPassword.valid"> Must meet requirements </mat-error>
    </mat-form-field>
    <br />

    <mat-form-field appearance="outline" class="form-field">
      <mat-label>Confirm your new password</mat-label>
      <input
        matInput
        [formControl]="passwordForm.controls.confirmNewPassword"
        [type]="hideConfirmNewPassword ? 'password' : 'text'"
        autocomplete="new-password"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideConfirmNewPassword = !hideConfirmNewPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideConfirmNewPassword"
      >
        <mat-icon>{{ hideConfirmNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error> Passwords must match </mat-error>
    </mat-form-field>

    <!--For password managers-->
    <input
      id="username"
      type="email"
      name="username"
      [value]="username$ | async"
      [hidden]="true"
      autocomplete="username"
    />
    <br />

    <button mat-flat-button color="primary" [disabled]="!passwordForm.valid" (click)="onSubmit(formDirective)">
      Update Password
    </button>
  </form>
  <mat-card [ngStyle]="{ flex: '1 1 auto' }">
    <mat-card-header>
      <mat-card-subtitle>Password Requirements:</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ul class="mat-body-strong">
        <li>Must contain at least 1 number</li>
        <li>
          Must contain at least 1 special character:
          <ul>
            <li>{{ '^ $ * . [] {}() ? -" ! @ # % & / \\ , > < \' : ; | _ ~ ` + =' }}</li>
          </ul>
        </li>
        <li>Must contain at least 1 uppercase letter</li>
        <li>Must contain at least 1 lowercase letter</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>
