import { Identifiable } from './_interfaceMixins';

export enum DebitType {
  Usage = 'Usage',
  KeepWarmFee = 'KeepWarmFee',
}

export interface Debit extends Identifiable {
  ownerId: string;
  type: DebitType;
  amount: string;
  date: string;
  compositeId: string;
  userGameId: string;
  reason?: string;
}
