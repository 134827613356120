import { Identifiable } from './_interfaceMixins';

export type RegionConfigName =
  | 'us-east-2'
  | 'us-east-1'
  | 'us-west-1'
  | 'us-west-2'
  | 'af-south-1'
  | 'ap-east-1'
  | 'ap-south-2'
  | 'ap-southeast-3'
  | 'ap-southeast-4'
  | 'ap-south-1'
  | 'ap-northeast-3'
  | 'ap-northeast-2'
  | 'ap-southeast-1'
  | 'ap-southeast-2'
  | 'ap-northeast-1'
  | 'ca-central-1'
  | 'eu-central-1'
  | 'eu-west-1'
  | 'eu-west-2'
  | 'eu-south-1'
  | 'eu-west-3'
  | 'eu-south-2'
  | 'eu-north-1'
  | 'eu-central-2'
  | 'il-central-1'
  | 'me-south-1'
  | 'me-central-1'
  | 'sa-east-1';

export interface RegionConfig extends Identifiable {
  name: RegionConfigName;
  active: boolean;
}

export type HumanReadableRegionName = string;
export const REGION_CONFIG_NAME_MAP: { [regionConfigName in RegionConfigName]: HumanReadableRegionName } = {
  'us-east-2': 'USA - Midwest',
  'us-east-1': 'USA - East',
  'us-west-1': 'USA - Southwest',
  'us-west-2': 'USA - Northwest',
  'af-south-1': 'South Africa',
  'ap-east-1': 'Hong Kong',
  'ap-south-2': 'India - Central',
  'ap-southeast-3': 'Indonesia',
  'ap-southeast-4': 'Australia - Southeast',
  'ap-south-1': 'India - West',
  'ap-northeast-3': 'Japan - Southwest',
  'ap-northeast-2': 'South Korea',
  'ap-southeast-1': 'Singapore',
  'ap-southeast-2': 'Australia - East',
  'ap-northeast-1': 'Japan - Central',
  'ca-central-1': 'Canada',
  'eu-central-1': 'Germany',
  'eu-west-1': 'Ireland',
  'eu-west-2': 'United Kingdom',
  'eu-south-1': 'Italy',
  'eu-west-3': 'France',
  'eu-south-2': 'Spain',
  'eu-north-1': 'Sweden',
  'eu-central-2': 'Switzerland',
  'il-central-1': 'Israel',
  'me-south-1': 'Bahrain',
  'me-central-1': 'UAE',
  'sa-east-1': 'Brazil',
};
