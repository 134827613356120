import { UserGame, UserGameState } from '../models/user-game';

export type ServerState =
  | 'serverIsStopping'
  | 'serverIsRestarting'
  | 'serverIsStarting'
  | 'serverIsRunning'
  | 'instanceIsOffline'
  | 'unknown';

export abstract class UserGameStateTracker {
  abstract userGame: UserGame;

  get userGameState(): UserGameState {
    return this.userGame.state || UserGameState.UNKNOWN;
  }
  get instanceIsRunning(): boolean {
    return (
      this.userGameState >= UserGameState.INSTANCE_RUNNING &&
      this.userGameState < UserGameState.USER_REQUESTED_TERMINATION
    );
  }

  get serverState(): ServerState {
    return getServerStateFromUserGameState(this.userGameState);
  }

  get serverIsStopping(): boolean {
    return this.serverState === 'serverIsStopping';
  }

  get serverIsRestarting(): boolean {
    return this.serverState === 'serverIsRestarting';
  }

  get serverIsStarting(): boolean {
    return this.serverState === 'serverIsStarting';
  }

  get serverIsRunning(): boolean {
    return this.serverState === 'serverIsRunning';
  }

  get instanceIsOffline(): boolean {
    return getServerStateFromUserGameState(this.userGameState) === 'instanceIsOffline';
  }
}

const getServerStateFromUserGameState = (userGameState: UserGameState): ServerState => {
  if (
    userGameState >= UserGameState.USER_REQUESTED_TERMINATION &&
    userGameState < UserGameState.ERROR_ON_USER_REQUEST
  ) {
    return 'serverIsStopping';
  }

  if (
    userGameState >= UserGameState.USER_REQUESTED_RESTART &&
    userGameState < UserGameState.USER_REQUESTED_TERMINATION
  ) {
    return 'serverIsRestarting';
  }

  if (userGameState >= UserGameState.USER_REQUESTED_START && userGameState < UserGameState.GAME_SERVER_RUNNING) {
    return 'serverIsStarting';
  }

  if (userGameState === UserGameState.GAME_SERVER_RUNNING) {
    return 'serverIsRunning';
  }

  if (userGameState <= UserGameState.INSTANCE_OFFLINE) {
    return 'instanceIsOffline';
  }

  return 'unknown';
};
