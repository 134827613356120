export type UserGameClass =
  | 'Mini'
  | 'Basic'
  | 'Premium'
  | 'Ultra'
  | '2xUltra'
  | '3xUltra'
  | '4xUltra'
  | '5xUltra'
  | '6xUltra'
  | '7xUltra'
  | '8xUltra';

export const classOptions: UserGameClass[] = [
  'Mini',
  'Basic',
  'Premium',
  'Ultra',
  '2xUltra',
  '3xUltra',
  '4xUltra',
  '5xUltra',
  '6xUltra',
  '7xUltra',
  '8xUltra',
];

type CurrentInstanceType =
  | 't3.small'
  | 't3.medium'
  | 'c7i.large'
  | 'c7i.xlarge'
  | 'c7i.2xlarge'
  | 'c7i.4xlarge'
  | 'c7i.8xlarge'
  | 'c7i.12xlarge'
  | 'c7i.16xlarge'
  | 'c7i.24xlarge'
  | 'c7i.48xlarge';

const classToInstanceMap: { [key in UserGameClass]: CurrentInstanceType } = {
  Mini: 't3.small',
  Basic: 't3.medium',
  Premium: 'c7i.large',
  Ultra: 'c7i.xlarge',
  '2xUltra': 'c7i.2xlarge',
  '3xUltra': 'c7i.4xlarge',
  '4xUltra': 'c7i.8xlarge',
  '5xUltra': 'c7i.12xlarge',
  '6xUltra': 'c7i.16xlarge',
  '7xUltra': 'c7i.24xlarge',
  '8xUltra': 'c7i.48xlarge',
};

const awsInstanceToBaseCostMap: { [key in CurrentInstanceType]: number } = {
  't3.small': 0.0208,
  't3.medium': 0.0416,
  'c7i.large': 0.08925,
  'c7i.xlarge': 0.1785,
  'c7i.2xlarge': 0.357,
  'c7i.4xlarge': 0.714,
  'c7i.8xlarge': 1.428,
  'c7i.12xlarge': 2.142,
  'c7i.16xlarge': 2.856,
  'c7i.24xlarge': 4.284,
  'c7i.48xlarge': 8.568,
};

const INSTANCE_COST_MULTIPLIER = 2;

export const getClassHourlyCost = (userGameClass: UserGameClass): number =>
  awsInstanceToBaseCostMap[classToInstanceMap[userGameClass]] * INSTANCE_COST_MULTIPLIER;

export const getClassMinuteCost = (userGameClass: UserGameClass): number => getClassHourlyCost(userGameClass) / 60;
