import { Injectable } from '@angular/core';
import { AppApiService } from './app-api.service';
import { Debit } from '../models/debit';
import { Credit } from '../models/credit';
import { Store } from '@kto/rxjs-observable-store';
import { distinctUntilChanged, filter, tap } from 'rxjs';
import { listRequiredModelledItem, modelledListIdsUnchanged } from '../util/rxjs-pipes';
import { UserService } from './user.service';

interface CreditsAndDebits {
  credits: {
    [itemId: string]: Credit;
  };
  debits: {
    [itemId: string]: Debit;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CreditDebitService extends Store<CreditsAndDebits> {
  private ownerId?: string;
  constructor(private appApiService: AppApiService, private userService: UserService) {
    super({
      credits: {},
      debits: {},
    });

    this.appApiService.credit$
      .pipe(
        distinctUntilChanged(),
        tap((creditStoreState) => {
          if (!creditStoreState) {
            this.patchState({}, 'credits');
          }
        }),
        filter((creditStoreState) => !!creditStoreState),
        listRequiredModelledItem(),
        modelledListIdsUnchanged()
      )
      .subscribe({
        next: (credits) => credits.forEach((credit) => this.patchState(credit, 'credits', credit.__id)),
      });

    this.appApiService.debit$
      .pipe(
        distinctUntilChanged(),
        tap((debitStoreState) => {
          if (!debitStoreState) {
            this.patchState({}, 'debits');
          }
        }),
        filter((debitStoreState) => !!debitStoreState),
        listRequiredModelledItem(),
        modelledListIdsUnchanged()
      )
      .subscribe({
        next: (debits) => debits.forEach((debit) => this.patchState(debit, 'debits', debit.__id)),
      });

    this.userService.ownerId$.subscribe((ownerId) => (this.ownerId = ownerId));
  }

  loadCreditsAndDebits(): void {
    if (this.ownerId) {
      this.appApiService.listCredits$(this.ownerId);
      this.appApiService.listDebits$(this.ownerId);
    }
  }
}
