import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { InstanceTypeLabel } from '../../lib/instance-type';
import { classOptions, getClassHourlyCost } from '../../lib/user-game-class';

interface EstimatedUseForm {
  hoursPerMonth: FormControl<number | null>;
}
@Component({
  selector: 'app-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss'],
})
export class PricingTableComponent implements AfterViewInit, OnInit {
  @ViewChild(MatTable) table!: MatTable<PricingTableItem>;
  estimatedUseForm: FormGroup<EstimatedUseForm>;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns: Array<keyof PricingTableItem> = [
    'userGameClass',
    'pricePerHour',
    'hoursPerDollar',
    'dollarsPerMonth',
  ];

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.estimatedUseForm = this.fb.group<EstimatedUseForm>({
      hoursPerMonth: new FormControl(DEFAULT_HOURS_PER_MONTH),
    });
  }

  ngAfterViewInit(): void {
    this.table.dataSource = this.estimatedUseForm.controls.hoursPerMonth.valueChanges.pipe(
      startWith(this.estimatedUseForm.controls.hoursPerMonth.value),
      map((hoursPerMonth) => getPricingTableItems(hoursPerMonth ?? 0))
    );
  }

  protected readonly InstanceTypeLabel = InstanceTypeLabel;
}

export interface PricingTableItem {
  userGameClass: string;
  pricePerHour: number;
  hoursPerDollar: number;
  dollarsPerMonth: number;
}
const getPricingTableItems = (hoursPerMonth: number): PricingTableItem[] =>
  classOptions.map((userGameClass) => {
    const pricePerHour = getClassHourlyCost(userGameClass);
    return {
      userGameClass,
      pricePerHour,
      hoursPerDollar: 1 / pricePerHour,
      dollarsPerMonth: hoursPerMonth * pricePerHour,
    };
  });

const DEFAULT_HOURS_PER_MONTH = 10;
