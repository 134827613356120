<form *ngIf="estimatedUseForm">
  <mat-form-field appearance="outline" color="accent" [style.width.%]="100">
    <mat-label> Estimated hours per month </mat-label>
    <input matInput [formControl]="estimatedUseForm.controls.hoursPerMonth" />
  </mat-form-field>
</form>
<div class="mat-elevation-z8">
  <table mat-table aria-label="Elements">
    <ng-container matColumnDef="userGameClass">
      <th mat-header-cell *matHeaderCellDef>Server Type</th>
      <td mat-cell *matCellDef="let row">
        {{ row.userGameClass }}
      </td>
    </ng-container>

    <ng-container matColumnDef="pricePerHour">
      <th mat-header-cell *matHeaderCellDef>Price per Hour</th>
      <td mat-cell *matCellDef="let row">{{ row.pricePerHour | currency //noinspection UnresolvedReference }}</td>
    </ng-container>

    <ng-container matColumnDef="hoursPerDollar">
      <th mat-header-cell *matHeaderCellDef>Hours per $</th>
      <td mat-cell *matCellDef="let row">{{ row.hoursPerDollar | number //noinspection UnresolvedReference }}</td>
    </ng-container>

    <ng-container matColumnDef="dollarsPerMonth">
      <th mat-header-cell *matHeaderCellDef>Estimated Monthly Cost</th>
      <td mat-cell *matCellDef="let row">{{ row.dollarsPerMonth | currency //noinspection UnresolvedReference }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <!--suppress JSUnusedGlobalSymbols -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
