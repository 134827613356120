// noinspection JSUnusedGlobalSymbols

import { InstanceType } from '../lib/enums';
import { Identifiable, Ref_Game, Ref_Owner } from './_interfaceMixins';
import { RegionConfigName } from './region-config';
import { UserGameClass } from '../lib/user-game-class';

export enum UserGameState {
  DELETING = -1,
  UNKNOWN = 0,
  INSTANCE_OFFLINE = 1,
  USER_REQUESTED_START = 5,
  INSTANCE_STARTING = 10,
  INSTANCE_RUNNING = 20,
  GAME_SERVER_RUNNING = 30,
  USER_REQUESTED_RESTART = 35,
  INSTANCE_RESTARTING = 40,
  USER_REQUESTED_TERMINATION = 45,
  INSTANCE_TERMINATING = 50,
  ERROR_ON_USER_REQUEST = 55,
}

export interface UserGame extends Identifiable, Ref_Game, Ref_Owner {
  name: string;
  instanceType?: InstanceType;
  class: UserGameClass;
  instanceRegion: RegionConfigName;
  instanceId?: string;
  state?: UserGameState;
  publicIpAddress?: string;
  ftpUsername?: string;
  ftpPassword?: string;
}
