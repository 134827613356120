import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UserGame, UserGameState } from '../../models/user-game';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { PathParts } from '../../routing/lib';
import { NonNullableTypedModelledFormGroup } from '../../util/forms';
import { UserGameService } from '../../services/user-game.service';
import { Subject, takeUntil } from 'rxjs';
import { StoredStatus } from '@kto/modelled-api-store-service';
import { GameConfig, GameConfigItem } from '../../interfaces/game-config-item';
import { Storage } from 'aws-amplify';
import { REGION_CONFIG_NAME_MAP } from '../../models/region-config';
import { classOptions, UserGameClass } from '../../lib/user-game-class';

interface UserGameFormChanges extends SimpleChanges {
  userGame: SimpleChange;
}

@Component({
  selector: 'app-user-game-form',
  templateUrl: './user-game-form.component.html',
  styleUrls: ['./user-game-form.component.scss'],
})
export class UserGameFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() userGame: UserGame;
  @Input() defaultGameConfigItems: GameConfigItem[];
  gameConfig: GameConfig;
  userGameForm: NonNullableTypedModelledFormGroup<UserGame, 'name' | 'class'>;
  classOptions = classOptions;
  private ngUnsubscribe = new Subject<void>();

  get disabled(): boolean {
    return this.userGameForm?.disabled ?? true;
  }

  get instanceIsRunning(): boolean {
    return this.userGame.state !== UserGameState.INSTANCE_OFFLINE;
  }

  get humanReadableRegion(): string {
    return REGION_CONFIG_NAME_MAP[this.userGame.instanceRegion];
  }

  constructor(private fb: NonNullableFormBuilder, private router: Router, private userGameService: UserGameService) {}

  ngOnInit(): void {
    this.subscribeToStatus();
  }
  async ngOnChanges(changes: UserGameFormChanges): Promise<void> {
    await this.createGameConfigForm();
    if (changes.userGame && !changes.userGame.firstChange) {
      const {
        previousValue: { __id: previousId },
        currentValue: { __id: currentId },
      }: { previousValue: UserGame; currentValue: UserGame } = changes.userGame;
      if (previousId !== currentId) {
        this.subscribeToStatus();
      }
    }
  }

  private subscribeToStatus(): void {
    this.ngUnsubscribe.next();
    this.userGameService
      .getStoredUserGameStatus(this.userGame.__id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((status) => this.disableForm(status === StoredStatus.PENDING || status === StoredStatus.REMOVED));
  }

  private async createGameConfigForm(): Promise<void> {
    const userGame = this.userGame;
    this.userGameForm = this.fb.group({
      name: new FormControl(userGame.name) as FormControl<string>,
      class: new FormControl(userGame.class) as FormControl<UserGameClass>,
    });
    await this.loadExistingGameConfig();
  }

  private async hasExistingConfig(userGameId: string): Promise<boolean> {
    // List the user's game configs from S3
    const prefix = `userGames/${userGameId}/`;

    const result = await Storage.list(prefix, {
      level: 'private',
      pageSize: 10,
    });
    return !!result.results.length;
  }

  private async getCurrentGameConfig(userGameId: string): Promise<Record<string, any>> {
    const url = `userGames/${userGameId}/gameConfig.json`;
    const result = await Storage.vault.get(url, {
      download: true,
      level: 'private',
      cacheControl: 'no-cache',
    });
    if (result && result.Body) {
      const arrayBuffer = await (result.Body as Blob).arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const textDecoder = new TextDecoder('utf-8');
      return JSON.parse(textDecoder.decode(uint8Array));
    } else {
      throw new Error('Did not retrieve valid UserGame configuration');
    }
  }

  private async loadExistingGameConfig(): Promise<void> {
    // Check if the user already has a config for this game, and if so, populate the form values
    try {
      if (!(await this.hasExistingConfig(this.userGame.__id))) {
        this.gameConfig = { configItems: this.defaultGameConfigItems };
      } else {
        const currentGameConfig = await this.getCurrentGameConfig(this.userGame.__id);
        const configItems: GameConfigItem[] = this.defaultGameConfigItems.map((defaultConfigItem): GameConfigItem => {
          const existingConfigValue = currentGameConfig[defaultConfigItem.name];
          return existingConfigValue ? { ...defaultConfigItem, value: existingConfigValue } : { ...defaultConfigItem };
        });
        this.gameConfig = { configItems };
      }
    } catch (error) {
      console.error(error);
      await this.router.navigate([PathParts.error]);
    }
  }

  onSubmitGameConfig() {
    this.disableForm(true);
    const newUserGame: UserGame = {
      ...this.userGame,
      ...this.userGameForm.value,
    };
    this.userGameService.updateUserGame(this.userGame.__id, newUserGame);
  }

  async onCancelGameConfig() {
    await this.router.navigate([PathParts.mygames]);
  }

  disableForm(state: boolean): void {
    if (this.userGameForm) {
      state ? this.userGameForm.disable() : this.userGameForm.enable();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
